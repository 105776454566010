import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function Agent() {
  return (
    <>
        <Header/>
        <section className="flat-title-page">
                <div className="container">
                    <h2 className="text-center">Our Agents</h2>
                   
                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li>/ Agent</li>
                    </ul>
                    
                </div>
            </section>
<div>Agent</div>
<Footer/>
    </>

  )
}

export default Agent