import Footer from "../../Components/Footer"
import Header from "../../Components/Header"

const Favourites=()=>{
    return(<>
    <Header></Header>
    <p>Favourites</p>
    <Footer></Footer>
    </>)
}

export default Favourites