import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HomeSlider from './home_slider'
import PropertyType from './property_type'
import HomeTestimonials from './home_testimonials'
import HomeBlogs from './home_blogs'
import HomeCities from './home_cities'
import FeaturedProperties from './home_featured_properties'
import HomeAboutUs from './home_about_us'
import { useState, useEffect, useRef } from "react"
import { ApiService } from '../../Components/Services/apiservices'
import { useNavigate } from 'react-router-dom'
import PropertyBox from '../../Components/Element/property_box'
import FeaturedProjects from './home_featured_projects'


function Home() {
    const navigate = useNavigate()
    const [featuredpropertiesdata, setfeaturedPropertiesdata] = useState([])
    const [imgUrl, setimgUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const didMountRef = useRef(true)
   
    useEffect(() => {
        if (didMountRef.current) {
            getfeaturedproperty()
        }
        didMountRef.current = false
    }, [])
    const getfeaturedproperty = () => {
        setIsLoading(true)
        ApiService.fetchData('featuredpropertylist ').then((res) => {
            if (res.status == 'success') {
                setimgUrl(res.property_img_url)
                setfeaturedPropertiesdata(res.resPropertyData)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500)
            }
            else {
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }
    return (
        <>
            <Header />
            {/* <!-- Slider --> */}
          <HomeSlider/>
            {/* <!-- End Slider --> */}

            {/* <!-- Categories --> */}
           <PropertyType/>
            {/* <!-- End Categories --> */}
               {/* <!-- Recommended PROJECTS --> */}
           <FeaturedProjects/>
            {/* <!-- End Recommended PROJECTS --> */}
            {/* <!-- Recommended Properties --> */}
           <FeaturedProperties/>
            {/* <!-- End Recommended Properties --> */}
            <HomeAboutUs/>
            {/* <!-- Service --> */}
            <section className="flat-section-v3 flat-service-v2 bg-surface">
                <div className="container">
                    <div className="row wrap-service-v2" >
                        <div className="col-lg-6">
                            <div className="box-left wow fadeInLeftSmall" data-wow-delay=".2s" data-wow-duration="2000ms">
                                <div className="box-title">
                                    <div className="text-subtitle text-primary">Why Choose Us</div>
                                    <h4 className="mt-4">Discover What Sets Our Real Estate Expertise Apart</h4>
                                </div>
                                <p>At Homeya, our unwavering commitment lies in crafting unparalleled real estate journeys. Our seasoned professionals, armed with extensive market knowledge, walk alongside you through every phase of your property endeavor. We prioritize understanding your unique aspirations, tailoring our expertise to match your vision.</p>
                                <ul className="list-view">
                                    <li>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 15.9947C12.4183 15.9947 16 12.4154 16 8C16 3.58462 12.4183 0.00524902 8 0.00524902C3.58172 0.00524902 0 3.58462 0 8C0 12.4154 3.58172 15.9947 8 15.9947Z" fill="#198754"/>
                                            <path d="M7.35849 12.2525L3.57599 9.30575L4.65149 7.9255L6.97424 9.735L10.8077 4.20325L12.2462 5.19975L7.35849 12.2525Z" fill="white"/>
                                        </svg>
                                        Transparent Partnerships 
                                    </li>
                                    <li>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 15.9947C12.4183 15.9947 16 12.4154 16 8C16 3.58462 12.4183 0.00524902 8 0.00524902C3.58172 0.00524902 0 3.58462 0 8C0 12.4154 3.58172 15.9947 8 15.9947Z" fill="#198754"/>
                                            <path d="M7.35849 12.2525L3.57599 9.30575L4.65149 7.9255L6.97424 9.735L10.8077 4.20325L12.2462 5.19975L7.35849 12.2525Z" fill="white"/>
                                        </svg>
                                        Proven Expertise 
                                    </li>
                                    <li>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 15.9947C12.4183 15.9947 16 12.4154 16 8C16 3.58462 12.4183 0.00524902 8 0.00524902C3.58172 0.00524902 0 3.58462 0 8C0 12.4154 3.58172 15.9947 8 15.9947Z" fill="#198754"/>
                                            <path d="M7.35849 12.2525L3.57599 9.30575L4.65149 7.9255L6.97424 9.735L10.8077 4.20325L12.2462 5.19975L7.35849 12.2525Z" fill="white"/>
                                        </svg>
                                        Customized Solutions
                                    </li>
                                    <li>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 15.9947C12.4183 15.9947 16 12.4154 16 8C16 3.58462 12.4183 0.00524902 8 0.00524902C3.58172 0.00524902 0 3.58462 0 8C0 12.4154 3.58172 15.9947 8 15.9947Z" fill="#198754"/>
                                            <path d="M7.35849 12.2525L3.57599 9.30575L4.65149 7.9255L6.97424 9.735L10.8077 4.20325L12.2462 5.19975L7.35849 12.2525Z" fill="white"/>
                                        </svg>
                                        Local Area Knowledge
                                    </li>
                                </ul>
                                <a href="contact.html" className="btn-view"><span className="text">Contact Us</span> <span className="icon icon-arrow-right2"></span> </a>
                            </div>
                            
                        </div>
                        <div className="col-lg-6">
                            <div className="box-right wow fadeInRightSmall" data-wow-delay=".2s" data-wow-duration="2000ms">
                                <div className="box-service style-1 hover-btn-view">
                                    <div className="icon-box">
                                        <span className="icon icon-buy-home"></span>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Buy A New Home</h6>
                                        <p className="description">Explore diverse properties and expert guidance for a seamless buying experience.</p>
                                        <a href="#" className="btn-view style-1"><span className="text">Learn More</span> <span className="icon icon-arrow-right2"></span> </a>
                                    </div>
                                </div>
                                <div className="box-service style-1 hover-btn-view">
                                    <div className="icon-box">
                                        <span className="icon icon-rent-home"></span>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Rent a home</h6>
                                        <p className="description">Explore a diverse variety of listings tailored precisely to suit your unique lifestyle needs.</p>
                                        <a href="#" className="btn-view style-1"><span className="text">Learn More</span> <span className="icon icon-arrow-right2"></span> </a>
                                    </div>
                                </div>
                                <div className="box-service style-1 hover-btn-view">
                                    <div className="icon-box">
                                        <span className="icon icon-sale-home"></span>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Sell a home</h6>
                                        <p className="description">Showcasing your property's best features for a successful sale.</p>
                                        <a href="#" className="btn-view style-1"><span className="text">Learn More</span> <span className="icon icon-arrow-right2"></span> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Service --> */}

            {/* <!-- Location --> */}
           <HomeCities></HomeCities>
            {/* <!-- End Location --> */}
            {/* <!-- Property  --> */}
            {featuredpropertiesdata && featuredpropertiesdata?.length >0 ?<>
                <section className="flat-section flat-property-v2 bg-surface">
                <div className="container">
                    <div className="box-title style-1 wow fadeInUpSmall" data-wow-delay=".2s" data-wow-duration="2000ms">
                        <div className="box-left">
                            <div className="text-subtitle text-primary">Top Properties</div>
                            <h4 className="mt-4">Best Property Value</h4>
                        </div>
                        <a href="#" className="tf-btn primary size-1">View All</a>
                    </div>
                    <div className="grid-2 gap-30 wow fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
                        {featuredpropertiesdata?.map((item , index)=>{
                            return(<>
                            <PropertyBox propertydetail={item} imgUrl={imgUrl}></PropertyBox>
                            
                            </>)
                        })}
                      
                    </div>
                </div>
            </section>
            
            
            
            </>:''}
          
            {/* <!-- End Property  --> */}
            {/* <!-- Agents --> */}
            <section className="flat-section flat-agents">
                <div className="container">
                    <div className="box-title text-center wow fadeInUpSmall" data-wow-delay=".2s" data-wow-duration="2000ms">
                        <div className="text-subtitle text-primary">Our Teams</div>
                        <h4 className="mt-4">Meet Our Agents</h4>
                    </div>
                    <div className="row wow fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
                        <div className="box col-lg-4 col-sm-6">
                            <div className="box-agent style-1 hover-img">
                                <a href="#" className="box-img img-style">
                                    <img src="images/agents/agent-lg-1.jpg" alt="image-agent"/>
                                    <ul className="agent-social">
                                        <li><span className="icon icon-facebook"></span></li>
                                        <li><span className="icon icon-linkedin"></span></li>
                                        <li><span className="icon icon-twitter"></span></li>
                                        <li><span className="icon icon-instagram"></span></li>
                                    </ul>
                                </a>
                                <a href="#" className="content">
                                    <div className="info">
                                        <h6 className="link">Jack Halow</h6>
                                        <p className="mt-4 text-variant-1">CEO & Founder</p>
                                    </div>
                                    <span className="icon-phone"></span>
                                </a>
                            </div>
                        </div>
                        <div className="box col-lg-4 col-sm-6">
                            <div className="box-agent style-1 hover-img">
                                <a href="#" className="box-img img-style">
                                    <img src="images/agents/agent-lg-2.jpg" alt="image-agent"/>
                                    <ul className="agent-social">
                                        <li><span className="icon icon-facebook"></span></li>
                                        <li><span className="icon icon-linkedin"></span></li>
                                        <li><span className="icon icon-twitter"></span></li>
                                        <li><span className="icon icon-instagram"></span></li>
                                    </ul>
                                </a>
                                <a href="#" className="content">
                                    <div className="info">
                                        <h6 className="link">John Smith</h6>
                                        <p className="mt-4 text-variant-1">Property Manager</p>
                                    </div>
                                    <span className="icon-phone"></span>
                                </a>
                            </div>
                        </div>
                        <div className="box col-lg-4 col-sm-6">
                            <div className="box-agent style-1 hover-img">
                                <a href="#" className="box-img img-style">
                                    <img src="images/agents/agent-lg-3.jpg" alt="image-agent"/>
                                    <ul className="agent-social">
                                        <li><span className="icon icon-facebook"></span></li>
                                        <li><span className="icon icon-linkedin"></span></li>
                                        <li><span className="icon icon-twitter"></span></li>
                                        <li><span className="icon icon-instagram"></span></li>
                                    </ul>
                                </a>
                                <a href="#" className="content">
                                    <div className="info">
                                        <h6 className="link">Chris Patt</h6>
                                        <p className="mt-4 text-variant-1">Administrative Staff</p>
                                    </div>
                                    <span className="icon-phone"></span>
                                </a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            {/* <!-- End Agents --> */}

            {/* <!-- Testimonial --> */}
            <HomeTestimonials/>
            {/* <!-- End Testimonial -->
            <!-- Latest New --> */}
           <HomeBlogs/>
            {/* <!-- End Latest New --> */}








            <Footer />

        </>

    )
}

export default Home