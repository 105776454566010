import Footer from "../../Components/Footer"
import Header from "../../Components/Header"

const MyProperties=()=>{
    return(<>
    <Header></Header>
    <p>My Properties</p>
    <Footer></Footer>
    </>)
}

export default MyProperties