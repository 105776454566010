import { useEffect, useState } from "react";
import { validEmail, validNumber } from "../../../Components/Element/Regex";
import { useParams } from "react-router-dom";
import { ApiService } from "../../../Components/Services/apiservices";


const ProjectFooter = ({projectDetail, brochureurl, projectBaseUrl}) => {

    const { slug } = useParams()
    const [errorMessage, setErrorMessage] = useState("");    
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [enquiryUserdetail, setenquiryUserdetail] = useState({
        enquired_user_name: "",
        enquired_user_email: "",
        enquired_user_mobile: "",
        enquired_message: "",
    })

    const onInputChange = (e) => {
        setErrorMessage("");
        setSuccessMessage("");
        const { name, value } = e.target;
        console.log(value,"dddd")
        setenquiryUserdetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== "") {
            e.target.style.border = "";
        }
    };

    const submitEnquire = (e) => {
        e.preventDefault()
        let counter = 0;
        const myElements = document.getElementsByClassName("headerformrequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === "") {

                myElements[i].style.border = "1px solid red";
                counter++;
            } else {
                myElements[i].style.border = "";
            }
        }
        console.log(counter);
        if (counter == 0) {
            if (!validEmail.test(enquiryUserdetail.enquired_user_email)) {
                setErrorMessage("Please Enter Valid Email");
                return false;
            }
            if (!validNumber.test(enquiryUserdetail.enquired_user_mobile)) {
                setErrorMessage("Please Enter Valid Number");
                return false;
            }
            setisLoading(true)
            const dataString = {
                enquiry_name: enquiryUserdetail.enquired_user_name,
                enquiry_email: enquiryUserdetail.enquired_user_email,
                enquiry_mobile: enquiryUserdetail.enquired_user_mobile,
                enquiry_project_id: slug,
                enquired_message: "E-Brochure Download"
            };
            console.log(dataString);
            ApiService.postData("projectenquiry", dataString).then((res) => {
                if (res.status == "success") {
                    setSuccessMessage("Thank you! Brochure is being downloading!");
                    document.getElementById("downlaodheadernow").click();                    
                    document.getElementById("closeheaderModel").click();
                    setenquiryUserdetail({enquired_user_name: "",
                        enquired_user_email: "",})
                    setTimeout(() => {
                        setSuccessMessage("")
                    }, 1000);
                    setisLoading(false)
                } else {
                    setErrorMessage(res.message);
                    setisLoading(false)
                }
            }).catch(() => {
                setisLoading(false)
            });
        }
    };



    return <>
    <footer className="footer">
        <div className="top-footer">
            <div className="container">
                <div className="content-footer-top">
                    <div className="footer-logo">
                    {projectDetail && projectDetail.user?.user_logo !== undefined && projectDetail.user?.user_logo !== null && (
                    <img src={projectDetail && projectDetail.user?.user_logo !== undefined && projectDetail.user?.user_logo !== null ? projectDetail.user?.user_logo:"/images/logo.png"}
                    alt="logo-footer" width="174" height="44" />
                    )}
                    </div>
                    <div className="wd-social">
                    <span>Follow Us:</span>
                    <ul className="list-social d-flex align-items-center">
                    <li><a href="#" className="box-icon w-40 social"><i className="icon icon-facebook"></i></a></li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="inner-footer">
            <div className="container">
                <div className="row">
                    {projectDetail.footer_columns && projectDetail.footer_columns > 0 && (
                        <>
                        {projectDetail.project_footer_desc1 && projectDetail.project_footer_desc1 !="" && (
                            <div className="col" dangerouslySetInnerHTML={{ __html: projectDetail.project_footer_desc1 }}></div>
                        )}
                        {projectDetail.project_footer_desc2 && projectDetail.project_footer_desc2 !="" && (
                            <div className="col" dangerouslySetInnerHTML={{ __html: projectDetail.project_footer_desc2 }}></div>
                        )}
                        {projectDetail.project_footer_desc3 && projectDetail.project_footer_desc3 !="" && (
                            <div className="col" dangerouslySetInnerHTML={{ __html: projectDetail.project_footer_desc3 }}></div>
                        )}
                        {projectDetail.project_footer_desc4 && projectDetail.project_footer_desc4 !="" && (
                            <div className="col" dangerouslySetInnerHTML={{ __html: projectDetail.project_footer_desc4 }}></div>
                        )}
                        {projectDetail.project_footer_desc5 && projectDetail.project_footer_desc5 !="" && (
                            <div className="col" dangerouslySetInnerHTML={{ __html: projectDetail.project_footer_desc5 }}></div>
                        )}
                        {projectDetail.project_footer_desc6 && projectDetail.project_footer_desc6 !="" && (
                            <div className="col" dangerouslySetInnerHTML={{ __html: projectDetail.project_footer_desc6 }}></div>
                        )}
                        </>
                    )}
                    
                </div>
            </div>
        </div>
        <div className="bottom-footer">
            <div className="container">
                <div className="content-footer-bottom" style={{justifyContent:'center'}}>
                <div className="copyright">Copyright @ 2024 . All Rights Reserved . Design & Developed By Proopify</div>
                </div>
            </div>
        </div>
    </footer>

    <div className="modal fade" id="modalheaderContact" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="row">
                        <div className="col-lg-12">
                            
                            <a href={brochureurl + projectDetail?.project_e_brochure} download  target="_blank" id="downlaodheadernow" className="d-none">Download</a>
                            <form action="#" className="box-contact-v2 flat-account">
                            <span className="close-modal icon-close2" id="closeheaderModel" data-bs-dismiss="modal"></span>
                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                )}
                                {successMessage && (
                                    <div className="alert alert-success" role="alert">
                                        {successMessage}
                                    </div>
                                )}
                                <div className="box">
                                    <label htmlFor="name" className="label">Name:</label>
                                    <input type="text" className="form-control headerformrequired" value={enquiryUserdetail?.enquired_user_name} name="enquired_user_name" onChange={(e) => { onInputChange(e) }} />
                                </div>
                                <div className="box">
                                    <label htmlFor="mobile" className="label">Mobile Number:</label>
                                    <input type="text" className="form-control headerformrequired" name="enquired_user_mobile" value={enquiryUserdetail?.enquired_user_mobile} onChange={(e) => { onInputChange(e) }}  />
                                </div>
                                <div className="box">
                                    <label htmlFor="email" className="label">Email:</label>
                                    <input type="email" className="form-control headerformrequired" name="enquired_user_email" value={enquiryUserdetail?.enquired_user_email} onChange={(e) => { onInputChange(e) }} />
                                </div>
                                <div className="box">
                                    <button className="tf-btn primary" onClick={(e) => { submitEnquire(e) }}>
                                    {isLoading ? (
                                                        <img src="/images/loder01.gif" width="60px" height="11px" />
                                                    ) : (
                                                        "Submit"
                                                    )}
                                        
                                        </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </>
}

export default ProjectFooter;