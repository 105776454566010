import React, { useEffect, useRef, useState,useCallback } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { ApiService } from '../../Components/Services/apiservices';
import constant from '../../Components/Services/constant';

function PropertyType() {
    const didMountRef = useRef(true)
    const [propertyType, setPropertyType] = useState([])
    const [propertyImageUrl, setPropertyImageUrl] = useState("")


    useEffect(() => {
        if (didMountRef.current) {
            getPropertyData()
        }
        didMountRef.current = false
    })

    const getPropertyData = () => {
       
        ApiService.fetchData("getallpropertytype").then((res) => {
            if (res.status === "success") {
                setPropertyType(res.allTypes)
                setPropertyImageUrl(res.type_image_path)


            }
        })
    }
    const sliderRef = useRef(null);
    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);
  
    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, [])
    return (
        <section className="flat-section flat-categories">
            <div className="container">
                <div className="box-title style-1 wow fadeInUpSmall" data-wow-delay=".2s" data-wow-duration="2000ms">
                    <div className="box-left">
                        <div className="text-subtitle text-primary">Property Type</div>
                        <h4 className="mt-4">Try Searching For</h4>
                    </div>
                    <a href="#" className="btn-view"><span className="text">View All Services</span> <span className="icon icon-arrow-right2"></span> </a>
                </div>

                <div className="wrap-categories  wow fadeInUpSmall" data-wow-delay=".2s" data-wow-duration="2000ms">
                    <Swiper
                        spaceBetween={15}
                        slidesPerView={6}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        className='swiper tf-sw-categories'
                        ref={sliderRef}
                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            480: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            640: {
                                slidesPerView: 3,
                                spaceBetween: 15,
                            },
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 5,
                                spaceBetween: 15,
                            },
                            1280: {
                                slidesPerView: 6,
                                spaceBetween: 15,
                            },
                        }}
                    >
                        {propertyType.map((property, index) => (
                            <SwiperSlide key={index}>
                                <a href={`/property/${property.type_slug}`} className="homeya-categories">
                                    <div className="icon-box">
                                    <img src={property.types_image ? propertyImageUrl+property.types_image : constant.DEFAULT_IMAGE}></img>
                                    </div>
                                    <div className="content text-center">
                                        <h6>{property.types_name}</h6>
                                        {/* <p className="mt-4 text-variant-1">{property.properties_count} Property</p> */}
                                    </div>
                                </a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="box-navigation">
                        <div className="navigation style-1 swiper-nav-next nav-next-category" onClick={handlePrev}><span className="icon icon-arr-l"></span></div>
                        <div className="navigation style-1 swiper-nav-prev nav-prev-category"onClick={handleNext} ><span className="icon icon-arr-r"></span></div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default PropertyType