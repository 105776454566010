import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function BlogDetail() {
  return (
    <>

    <Header/>

    <div>BlogDetail</div>
    <Footer/>
    </>
  )
}

export default BlogDetail