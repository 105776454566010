import { useNavigate } from "react-router-dom"
import constant from "../Services/constant"
import LoginModal from "./Modals/login_modal"
import { ApiService } from "../Services/apiservices"
import React, { useState } from "react"
import Loader from "react-js-loader";


const ProjectBox = ({ propertydetail, imgUrl }) => {
    const session_token = localStorage.getItem('USER_TOKEN')
    const navigate = useNavigate()
    const [spinnerloading, setspinnerloading] = useState('')

    const addtofav = (propertyId) => {
        setspinnerloading(propertyId.project_id)
        const dataString = {
            project_id: propertyId.project_id,
        };
        ApiService.postData("addtofav", dataString).then((res) => {
            if (res.data.status == "success") {
                setspinnerloading('')
                setTimeout(() => {
                    var element = document.getElementById("wishlisticon" + propertyId.project_id);
                    element.classList.remove("icon-heart-fill", "icon-heart");
                    if (res.data.notification === "ri-heart-fil") {
                        element.classList.add('icon-heart-fill');
                    } else {
                        element.classList.add('icon-heart');
                    }
                }, 100)
            } else {
                setspinnerloading('')
            }
        }).catch(() => {
            setspinnerloading('')
        });
    };
    return (<>

        <div className="homeya-box md" >
            <div className="archive-top">
                <div className="images-group">
                    <div className="images-style" onClick={() => { navigate(`/${propertydetail.project_url}`) }}>
                        <img src={propertydetail?.project_image ? imgUrl + propertydetail?.project_image : constant.DEFAULT_IMAGE} alt="img" />
                    </div>
                    <div className="top">
                        <ul className="d-flex gap-8 flex-column">
                            {propertydetail?.property_featured == 1 ? <> <li className="flag-tag success">Featured</li></> : ''}

                        </ul>
                        <ul className="d-flex gap-4">

                            {session_token && session_token !== null ? <>
                                {propertydetail.ufp_id > 0 ? (
                                    <li className="box-icon w-32"
                                        onClick={(e) =>
                                            addtofav(propertydetail)
                                        }
                                    >
                                        {spinnerloading == propertydetail.project_id ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> :
                                            <span className="icon icon-heart-fill" id={
                                                "wishlisticon" +
                                                propertydetail.project_id
                                            }></span>}
                                    </li>


                                ) : (<li className="box-icon w-32"

                                    onClick={(e) =>
                                        addtofav(propertydetail)
                                    }
                                >
                                    {spinnerloading == propertydetail.project_id ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> :
                                        <span className="icon icon-heart" id={
                                            "wishlisticon" +
                                            propertydetail.project_id
                                        }></span>}
                                </li>)}

                            </> : <> <li className="box-icon w-32" >
                                <a href="#modalLogin" data-bs-toggle="modal"> <span className="icon icon-heart"></span></a>

                            </li></>}


                            <li className="box-icon w-32">
                                <span className="icon icon-eye"></span>
                            </li>
                        </ul>
                    </div>

                </div>
                <div className="content">
                    <div className="text-1 text-capitalize"><a href={`/${propertydetail.project_url}`} className="link">{propertydetail?.project_name}</a></div>
                    {propertydetail?.project_user_name ? <p>By {propertydetail?.project_user_name}</p> : ""}
                    {propertydetail?.project_address ? <div className="desc"><i className="fs-16 icon icon-mapPin"></i><p>{propertydetail?.project_address}</p> </div> : ''}


                    {propertydetail?.project_area_start || propertydetail?.project_area_end ? <>
                        <ul className="meta-list">
                            <li className="item">
                                <i className="icon icon-ruler"></i>
                                <span>{propertydetail?.project_area_start} - {propertydetail?.project_area_end} SqFT</span>
                            </li>
                        </ul>
                    </> : ''}

                </div>
            </div>
            <div className="archive-bottom d-flex justify-content-between align-items-center">


                <div className="d-flex align-items-center">
                    {propertydetail.project_price_start || propertydetail?.project_price_end ? <>
                        <div className="h7 fw-7">₹{propertydetail.project_price_start}-{propertydetail?.project_price_end}</div>
                    </> : <div className="h7 fw-7">Price on request</div>}


                </div>
            </div>
        </div>

        <LoginModal></LoginModal>
    </>)
}

export default ProjectBox