import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import moment from "moment";
import constant from "../../Components/Services/constant";

function HomeBlogs() {
  const didMountRef = useRef(true);
  const [blogData, setBlogData] = useState([]);
  const [blogImagePath, setBlogImagePath] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (didMountRef.current) {
      getBlogData();
    }
    didMountRef.current = false;
  }, []);

  const getBlogData = () => {
    setIsLoading(true);
    ApiService.fetchData("featured-blog").then((res) => {
      if (res.status === "success") {
        setBlogData(res.resblogData);
        setBlogImagePath(res.blog_image_path);
      }
      setIsLoading(false);
    });
  };
  return (
    <>

      {blogData && blogData.length > 0 ?
        <>
          <section className="flat-section flat-latest-new wow fadeInUpSmall" data-wow-delay=".2s" data-wow-duration="2000ms">
            <div className="container">
              <div className="box-title text-center">
                <div className="text-subtitle text-primary">Latest New</div>
                <h4 className="mt-4">Helpful Propify Guides</h4>
              </div>
              <Swiper
                spaceBetween={30}
                slidesPerView={3}
                breakpoints={{

                  320: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
              >
                {blogData.map((value, index) => (
                  <SwiperSlide key={index}>
                    <div className="box">
                      <a href={`/blog/${value.blog_slug}`} className="flat-blog-item hover-img">
                        <div className="img-style">
                          <img src={value.blog_image ? `${blogImagePath}/${value.blog_image}` : constant.DEFAULT_IMAGE} alt={value.blog_image} />
                          <span className="date-post"> {moment(value.created_at).format('MMMM D, YYYY')}</span>
                        </div>
                        <div className="content-box">
                          <h6 className="title">
                            {value.blog_name}
                          </h6>
                          <p className="description">
                            {value.blog_short_description}
                          </p>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </section>
        </> : null}
    </>
  )
}

export default HomeBlogs