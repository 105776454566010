const PropertySearch=()=>{
    return(<>
      <section className="flat-filter-search-v2">
            <div className="flat-tab flat-tab-form">
                <ul className="nav-tab-form style-3" role="tablist">
                    <li className="nav-tab-item" role="presentation">
                        <a href="#forRent" className="nav-link-item active" data-bs-toggle="tab">For Rent</a>
                    </li>
                    <li className="nav-tab-item" role="presentation">
                        <a href="#forSale" className="nav-link-item" data-bs-toggle="tab">For Sale</a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade active show" role="tabpanel">
                        <div className="form-sl">
                            <form method="post">
                                <div className="wd-find-select">
                                    <div className="inner-group">
                                        <div className="form-group-1 search-form form-style">
                                            <label>Keyword</label>
                                            <input type="text" className="form-control" placeholder="Search Keyword." value="" name="s" title="Search for" required="" />
                                        </div>
                                        <div className="form-group-2 form-style">
                                            <label>Location</label>
                                            <div className="group-ip">
                                                <input type="text" className="form-control" placeholder="Search Location" value="" name="s" title="Search for" required="" />
                                                <a href="#" className="icon icon-location"></a>
                                            </div>
                                        </div>
                                        <div className="form-group-3 form-style">
                                            <label>Type</label>
                                            <div className="group-select">
                                                <div className="nice-select" tabindex="0"><span className="current">All</span>
                                                    <ul className="list">
                                                        <li data-value className="option selected">All</li>
                                                        <li data-value="villa" className="option">Villa</li>
                                                        <li data-value="studio" className="option">Studio</li>
                                                        <li data-value="office" className="option">Office</li>
                                                        <li data-value="house" className="option">House</li>


                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group-4 box-filter">
                                            <a className="filter-advanced pull-right">
                                                <span className="icon icon-faders"></span>
                                                <span className="text-1">Advanced</span>
                                            </a>
                                        </div>
                                    </div>
                                    <button type="submit" className="tf-btn primary">Find Properties</button>
                                </div>
                                <div className="wd-search-form">
                                    {/* <div className="grid-2 group-box group-price">
                                        <div className="widget-price">
                                            <div className="box-title-price">
                                                <span className="title-price">Price Range</span>
                                                <div className="caption-price">
                                                    <span>from</span>
                                                    <span id="slider-range-value1" className="fw-7"></span>
                                                    <span>to</span>
                                                    <span id="slider-range-value2" className="fw-7"></span>
                                                </div>
                                            </div>
                                            <div id="slider-range"></div>
                                            <div className="slider-labels">
                                                <div>
                                                    <input type="hidden" name="min-value" value="" />
                                                    <input type="hidden" name="max-value" value="" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="widget-price">
                                            <div className="box-title-price">
                                                <span className="title-price">Size Range</span>
                                                <div className="caption-price">
                                                    <span>from</span>
                                                    <span id="slider-range-value01" className="fw-7"></span>
                                                    <span>to</span>
                                                    <span id="slider-range-value02" className="fw-7"></span>
                                                </div>
                                            </div>
                                            <div id="slider-range2"></div>
                                            <div className="slider-labels">
                                                <div>
                                                    <input type="hidden" name="min-value2" value="" />
                                                    <input type="hidden" name="max-value2" value="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="grid-2 group-box">
                                        <div className="group-select grid-2">
                                            <div className="box-select">
                                                <label className="title-select text-variant-1">Rooms</label>
                                                <div className="nice-select" tabindex="0"><span className="current">2</span>
                                                    <ul className="list">
                                                        <li data-value="1" className="option">1</li>
                                                        <li data-value="2" className="option selected">2</li>
                                                        <li data-value="3" className="option">3</li>
                                                        <li data-value="4" className="option">4</li>
                                                        <li data-value="5" className="option">5</li>
                                                        <li data-value="6" className="option">6</li>
                                                        <li data-value="7" className="option">7</li>
                                                        <li data-value="8" className="option">8</li>
                                                        <li data-value="9" className="option">9</li>
                                                        <li data-value="10" className="option">10</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="box-select">
                                                <label className="title-select text-variant-1">Bathrooms</label>
                                                <div className="nice-select" tabindex="0"><span className="current">2</span>
                                                    <ul className="list">
                                                        <li data-value="1" className="option">1</li>
                                                        <li data-value="2" className="option selected">2</li>
                                                        <li data-value="3" className="option">3</li>
                                                        <li data-value="4" className="option">4</li>
                                                        <li data-value="5" className="option">5</li>
                                                        <li data-value="6" className="option">6</li>
                                                        <li data-value="7" className="option">7</li>
                                                        <li data-value="8" className="option">8</li>
                                                        <li data-value="9" className="option">9</li>
                                                        <li data-value="10" className="option">10</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="group-select grid-2">
                                            <div className="box-select">
                                                <label className="title-select text-variant-1">Bedrooms</label>
                                                <div className="nice-select" tabindex="0"><span className="current">2</span>
                                                    <ul className="list">
                                                        <li data-value="1" className="option">1</li>
                                                        <li data-value="2" className="option selected">2</li>
                                                        <li data-value="3" className="option">3</li>
                                                        <li data-value="4" className="option">4</li>
                                                        <li data-value="5" className="option">5</li>
                                                        <li data-value="6" className="option">6</li>
                                                        <li data-value="7" className="option">7</li>
                                                        <li data-value="8" className="option">8</li>
                                                        <li data-value="9" className="option">9</li>
                                                        <li data-value="10" className="option">10</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="box-select">
                                                <label className="title-select fw-5">Type</label>
                                                <div className="nice-select" tabindex="0"><span className="current">2</span>
                                                    <ul className="list">
                                                        <li data-value="1" className="option">1</li>
                                                        <li data-value="2" className="option selected">2</li>
                                                        <li data-value="3" className="option">3</li>
                                                        <li data-value="4" className="option">4</li>
                                                        <li data-value="5" className="option">5</li>
                                                        <li data-value="6" className="option">6</li>
                                                        <li data-value="7" className="option">7</li>
                                                        <li data-value="8" className="option">8</li>
                                                        <li data-value="9" className="option">9</li>
                                                        <li data-value="10" className="option">10</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* <div className="group-checkbox">
                                        <div className="text-1">Amenities:</div>
                                        <div className="group-amenities mt-8 grid-6">
                                            <div className="box-amenities">
                                                <fieldset className="amenities-item">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb1" checked />
                                                    <label for="cb1" className="text-cb-amenities">Air Condition</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb2" />
                                                    <label for="cb2" className="text-cb-amenities">Cable TV</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb3" />
                                                    <label for="cb3" className="text-cb-amenities">Ceiling Height</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb4" />
                                                    <label for="cb4" className="text-cb-amenities">Fireplace</label>
                                                </fieldset>
                                            </div>
                                            <div className="box-amenities">
                                                <fieldset className="amenities-item">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb5" />
                                                    <label for="cb5" className="text-cb-amenities">Disabled Access</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb6" checked />
                                                    <label for="cb6" className="text-cb-amenities">Elevator</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb7" />
                                                    <label for="cb7" className="text-cb-amenities">Fence</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb8" />
                                                    <label for="cb8" className="text-cb-amenities">Garden</label>
                                                </fieldset>
                                            </div>
                                            <div className="box-amenities">
                                                <fieldset className="amenities-item">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb9" checked />
                                                    <label for="cb9" className="text-cb-amenities">Floor</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb10" />
                                                    <label for="cb10" className="text-cb-amenities">Furnishing</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb11" checked />
                                                    <label for="cb11" className="text-cb-amenities">Garage</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb12" />
                                                    <label for="cb12" className="text-cb-amenities">Pet Friendly</label>
                                                </fieldset>
                                            </div>
                                            <div className="box-amenities">
                                                <fieldset className="amenities-item">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb13" />
                                                    <label for="cb13" className="text-cb-amenities">Heating</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb14" />
                                                    <label for="cb14" className="text-cb-amenities">Intercom</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb15" />
                                                    <label for="cb15" className="text-cb-amenities">Parking</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb16" />
                                                    <label for="cb16" className="text-cb-amenities">WiFi</label>
                                                </fieldset>
                                            </div>
                                            <div className="box-amenities">
                                                <fieldset className="amenities-item">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb17" />
                                                    <label for="cb17" className="text-cb-amenities">Renovation</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb18" />
                                                    <label for="cb18" className="text-cb-amenities">Security</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb19" />
                                                    <label for="cb19" className="text-cb-amenities">Swimming Pool</label>
                                                </fieldset>

                                            </div>
                                            <div className="box-amenities">
                                                <fieldset className="amenities-item">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb20" />
                                                    <label for="cb20" className="text-cb-amenities">Window Type</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb21" />
                                                    <label for="cb21" className="text-cb-amenities">Search property</label>
                                                </fieldset>
                                                <fieldset className="amenities-item mt-12">
                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb22" />
                                                    <label for="cb22" className="text-cb-amenities">Construction Year</label>
                                                </fieldset>
                                            </div>

                                        </div>

                                    </div> */}
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>)
}

export default PropertySearch