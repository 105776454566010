import Footer from "../../Components/Footer"
import Header from "../../Components/Header"

const Leads=()=>{
    return(<>
    <Header></Header>
    <p>Leads/Enquiry</p>
    <Footer></Footer>
    
    </>)
}

export default Leads