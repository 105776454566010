import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProjectHeader from './Elements/ProjectHeader';
import ProjectSlider from "./Elements/ProjectSlider";
import ProjectAbout from "./Elements/ProjectAbout";
import ProjectLocation from "./Elements/ProjectLocation";
import ProjectFloorplan from "./Elements/ProjectFloorplan";
import ProjectSpecifications from "./Elements/ProjectSpecifications";
import ProjectAmenities from "./Elements/ProjectAmenities";
import ProjectEBrochure from "./Elements/ProjectEBrochure";
import ProjectContact from "./Elements/ProjectContact";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import ProjectFooter from "./Elements/ProjectFooter";
import ProjectOverview from "./Elements/ProjectOverview";
import ProjectNearBy from "./Elements/ProjectNearBy";
import ProjectUnits from "./Elements/ProjectUnits";
import ProjectVideo from "./Elements/ProjectVideo";
import ProjectSlider2 from "./Elements/ProjectSlider2";

const ProjectDetail = () => {
    const { slug } = useParams()    
    const didMountRef = useRef(true)
    const [browserSectionData, setBrowserSectionData] = useState([])
    const [mobileSectionData, setMobileSectionData] = useState([])
    const [projectDetail, setProjectDetail] = useState({})
    const [projectBaseUrl, setProjectBaseUrl] = useState("")
    const [amenityimgurl, setamenityimgurl] = useState("")
    const [brochureurl, setbrochureurl] = useState('')
    const [gallaryImages, setGallaryImages] = useState([])
    const [floorplanimgUrl, setfloorplanimgUrl] = useState(''); 
    
    const navigate = useNavigate()

    useEffect(() => {
    if (didMountRef.current) {
        
        getProjectData()
    }
    didMountRef.current = false
    })

    
    const getProjectData = () => {
        const dataString = {
            project_url: slug
        }
        ApiService.postData('projectdetailslayout', dataString).then((res) => {
            if (res.status == 'success') {
                setProjectDetail(res.data)
                setProjectBaseUrl(res.project_img_url)
                setamenityimgurl(res.amenities_img_url)
                setbrochureurl(res.brochure_url)
                setGallaryImages(res.data.gallery)
                setfloorplanimgUrl(res.floorplan_url)
                setBrowserSectionData(res.resAppearanceHomeBrowserData);
                setMobileSectionData(res.resAppearanceHomeMobileData);
                
            }
            else if (res.status == 'session_expired' && res.message == 'Session expired') {
                localStorage.removeItem('USER_TOKEN')
                navigate('/')
            }

        }).catch((error) => {

        })    
    };

    const convertStyleStringToObject = (styleString) => {
        if (!styleString) return {}; // Return an empty object if styleString is null or undefined
        return styleString.split(';').reduce((styleObject, styleProperty) => {
          if (styleProperty) {
            const [property, value] = styleProperty.split(':');
            if (property && value) {
              const camelCasedProperty = property.trim().replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
              styleObject[camelCasedProperty] = value.trim();
            }
          }
          return styleObject;
        }, {});
      };


    return <>
        <ProjectHeader projectDetail={projectDetail} projectBaseUrl={projectBaseUrl}/>
        <BrowserView>
        {browserSectionData.length > 0 && (
          browserSectionData.map((valueSection, indexSection) => {
            const inlineStyle = convertStyleStringToObject(valueSection.home_inline_style);
            if (valueSection.home_layout_image) {
              inlineStyle.background = `url(${valueSection.home_layout_image}) ${valueSection.home_layout_background_repeat}`;              
            } 
            if (valueSection.home_layout_image_url) {
              inlineStyle.background = `url(${valueSection.home_layout_image_url}) ${valueSection.home_layout_background_repeat}`;              
            }

            return (
              <section id={(valueSection.home_extra_id != 'null') ? valueSection.home_extra_id:""} className={`${valueSection.home_top_spaced} ${valueSection.home_bottom_spaced} ${valueSection.home_extra_class}`} key={valueSection.home_id} style={inlineStyle}>
                <div className={`${valueSection.home_layout} ${valueSection.home_grid === 'g-0' ? 'px-0' : ''}`}>
                  <div className={`row ${valueSection.home_grid} ${valueSection.home_vertical_align} ${valueSection.home_horizontal_align}`}>
                    {valueSection.has_many_home_inner.length > 0 &&
                      valueSection.has_many_home_inner.map((valueRowInner, indexRowInner, valueSection) => (                        
                        <><div className={"col-lg-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                          {valueRowInner.has_many_home_details.length > 0 &&
                            valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                              let dataObj;
                              if (valueRow.home_type === 1) { //Slider
                                if(valueRow.home_slider_layout == 1){
                                  return (
                                    <ProjectSlider
                                    key={indexRow} 
                                    projectDetail={projectDetail}
                                    projectBaseUrl={projectBaseUrl}
                                    gallaryImages={gallaryImages}
                                    />
                                );
                                }else if(valueRow.home_slider_layout == 2){
                                  return (
                                    <ProjectSlider2
                                    key={indexRow} 
                                    projectDetail={projectDetail}
                                    projectBaseUrl={projectBaseUrl}
                                    gallaryImages={gallaryImages}
                                    />
                                );
                                }
                                
                              } else if (valueRow.home_type === 2) { //About
                                return (
                                    <ProjectAbout 
                                    projectDetail={projectDetail}
                                    />
                                  );
                              } else if (valueRow.home_type === 3) { //Location
                                return (
                                    <ProjectLocation 
                                    projectDetail={projectDetail}
                                    />
                                  );
                              } else if (valueRow.home_type === 4) { //Floor Plan
                                return (
                                    <ProjectFloorplan 
                                    projectDetail={projectDetail}
                                    floorplanimgUrl={floorplanimgUrl}

                                    />
                                  );
                              } else if (valueRow.home_type === 5) { // Amenities
                                return (
                                    <ProjectAmenities 
                                    projectDetail={projectDetail}
                                    amenityimgurl={amenityimgurl}
                                    />
                                  );
                              } else if (valueRow.home_type === 6) { //E-Brochure
                                return (
                                    <ProjectEBrochure 
                                    projectDetail={projectDetail}
                                    brochureurl={brochureurl}
                                    />
                                );
                              } else if (valueRow.home_type === 7) {  //Contact
                                
                                return (
                                    <ProjectContact
                                    projectDetail={projectDetail}
                                    />
                                );
                              }else if (valueRow.home_type === 8) {  //Image
                                const imageInlineStyle = convertStyleStringToObject(valueRow.home_custom_size);
                                return (
                                  <a href={valueRow.home_image_link} target={valueRow.home_target}>
                                    <img src={valueRow.home_image} key={indexRow} alt={valueRow.home_caption_text} className={`${valueRow.home_size}`} style={imageInlineStyle} />
                                  </a>
                                );
                                
                              }else if (valueRow.home_type === 9) {  //Text
                                return (
                                  <div key={indexRow}>
                                    {valueRow.home_text_title && (<p>{valueRow.home_text_title}</p>)}
                                    <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }}></div>
                                  </div>
                                );
                              }else if (valueRow.home_type === 10) {  //Title
                                
                                const teamInlineStyle = convertStyleStringToObject(valueRow.home_brij_inline_style);
                                return (
                                  <div className={`section-title ${valueRow.home_brij_align} ${valueRow.home_brij_extra_class}`} style={teamInlineStyle} key={indexRow}>
                                    {valueRow.home_brij_heading && React.createElement(
                                      valueRow.home_brij_heading_size || 'h1',
                                      null,
                                      valueRow.home_brij_heading
                                    )}
                                    {valueRow.home_brij_subheading && (<p>{valueRow.home_brij_subheading}</p>)}
                                  </div>
                                );
                              }else if (valueRow.home_type === 11) {  //Overview
                                
                                return (
                                    <ProjectOverview 
                                    projectDetail={projectDetail}
                                    />
                                );
                              }else if (valueRow.home_type === 12) {  //Units
                                
                                return (
                                  <ProjectUnits
                                    projectDetail={projectDetail}
                                    projectBaseUrl={projectBaseUrl}
                                    />
                                );
                              }else if (valueRow.home_type === 13) {  //Video
                                
                                return (
                                  <ProjectVideo
                                    projectDetail={projectDetail}
                                    />
                                );
                              }else if (valueRow.home_type === 14) {  //NearBy
                                
                                return (
                                  <ProjectNearBy
                                    projectDetail={projectDetail}
                                    />
                                );
                              }else if (valueRow.home_type === 15) {  //Specification
                                
                                return (
                                  <ProjectSpecifications
                                    projectDetail={projectDetail}
                                    amenityimgurl={amenityimgurl}
                                    />
                                );
                              }
                            })}
                        </div></>
                      ))}
                  </div>
                </div>
              </section>
            );
          })
        )}
        </BrowserView>
        <MobileView>
        {mobileSectionData.length > 0 && (
          mobileSectionData.map((valueSection, indexSection) => {
            const inlineStyle = convertStyleStringToObject(valueSection.home_inline_style);
            if (valueSection.home_layout_image) {
              inlineStyle.background = `url(${valueSection.home_layout_image}) ${valueSection.home_layout_background_repeat}`;
            }
            return (
              <section id={(valueSection.home_extra_id != 'null') ? valueSection.home_extra_id:""} className={`${valueSection.home_top_spaced} ${valueSection.home_bottom_spaced} ${valueSection.home_extra_class}`} key={valueSection.home_id} style={inlineStyle}>
                <div className={`${valueSection.home_layout} ${valueSection.home_grid === 'g-0' ? 'px-0' : ''}`}>
                  <div className={`row ${valueSection.home_grid} ${valueSection.home_vertical_align} ${valueSection.home_horizontal_align}`}>
                    {valueSection.has_many_home_inner.length > 0 &&
                      valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                        <><div className={"col-lg-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                          {valueRowInner.has_many_home_details.length > 0 &&
                            valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                              let dataObj;
                              if (valueRow.home_type === 1) { //Slider
                                if(valueRow.home_slider_layout == 1){
                                  return (
                                    <ProjectSlider
                                    key={indexRow} 
                                    projectDetail={projectDetail}
                                    projectBaseUrl={projectBaseUrl}
                                    gallaryImages={gallaryImages}
                                    />
                                );
                                }else if(valueRow.home_slider_layout == 2){
                                  return (
                                    <ProjectSlider2
                                    key={indexRow} 
                                    projectDetail={projectDetail}
                                    projectBaseUrl={projectBaseUrl}
                                    gallaryImages={gallaryImages}
                                    />
                                );
                                }
                                /* return (
                                    <ProjectSlider
                                    key={indexRow} 
                                    projectDetail={projectDetail}
                                    projectBaseUrl={projectBaseUrl}
                                    gallaryImages={gallaryImages}
                                    />
                                ); */
                              } else if (valueRow.home_type === 2) { //About
                                return (
                                    <ProjectAbout 
                                    projectDetail={projectDetail}
                                    />
                                  );
                              } else if (valueRow.home_type === 3) { //Location
                                return (
                                    <ProjectLocation 
                                    projectDetail={projectDetail}
                                    />
                                  );
                              } else if (valueRow.home_type === 4) { //Floor Plan
                                return (
                                    <ProjectFloorplan 
                                    projectDetail={projectDetail}
                                    floorplanimgUrl={floorplanimgUrl}

                                    />
                                  );
                              } else if (valueRow.home_type === 5) { //Amenities
                                return (
                                    <ProjectAmenities 
                                    projectDetail={projectDetail}
                                    amenityimgurl={amenityimgurl}
                                    />
                                  );
                              } else if (valueRow.home_type === 6) { //E-Brochure
                                return (
                                    <ProjectEBrochure 
                                    projectDetail={projectDetail}
                                    brochureurl={brochureurl}
                                    />
                                );
                              } else if (valueRow.home_type === 7) {  //Contact
                                return (
                                    <ProjectContact
                                    projectDetail={projectDetail}
                                    />
                                );
                              }else if (valueRow.home_type === 8) {  //Image
                                const imageInlineStyle = convertStyleStringToObject(valueRow.home_custom_size);
                                return (
                                  <a href={valueRow.home_image_link} target={valueRow.home_target}>
                                    <img src={valueRow.home_image} key={indexRow} alt={valueRow.home_caption_text} className={`${valueRow.home_size}`} style={imageInlineStyle} />
                                  </a>
                                );
                                
                              }else if (valueRow.home_type === 9) {  //Text
                                return (
                                  <div key={indexRow}>
                                    {valueRow.home_text_title && (<p>{valueRow.home_text_title}</p>)}
                                    <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }}></div>
                                  </div>
                                );
                              }else if (valueRow.home_type === 10) {  //Title
                                
                                const teamInlineStyle = convertStyleStringToObject(valueRow.home_brij_inline_style);
                                return (
                                  <div className={`section-title ${valueRow.home_brij_align} ${valueRow.home_brij_extra_class}`} style={teamInlineStyle} key={indexRow}>
                                    {valueRow.home_brij_heading && React.createElement(
                                      valueRow.home_brij_heading_size || 'h1',
                                      null,
                                      valueRow.home_brij_heading
                                    )}
                                    {valueRow.home_brij_subheading && (<p>{valueRow.home_brij_subheading}</p>)}
                                  </div>
                                );
                              }else if (valueRow.home_type === 11) {  //Overview
                                
                                return (
                                    <ProjectOverview 
                                    projectDetail={projectDetail}
                                    />
                                );
                              }else if (valueRow.home_type === 12) {  //Units
                                
                                return (
                                  <ProjectUnits
                                    projectDetail={projectDetail}
                                    projectBaseUrl={projectBaseUrl}
                                    />
                                );
                              }else if (valueRow.home_type === 13) {  //Video
                                
                                return (
                                  <ProjectVideo
                                    projectDetail={projectDetail}
                                    />
                                );
                              }else if (valueRow.home_type === 14) {  //NearBy
                                
                                return (
                                  <ProjectNearBy
                                    projectDetail={projectDetail}
                                    />
                                );
                              }else if (valueRow.home_type === 15) {  //Specification
                                
                                return (
                                  <ProjectSpecifications
                                    projectDetail={projectDetail}
                                    amenityimgurl={amenityimgurl}
                                    />
                                );
                              }
                            })}
                        </div></>
                      ))}
                  </div>
                </div>
              </section>
            );
          })
        )}

        </MobileView>
        <ProjectFooter projectDetail={projectDetail} brochureurl={brochureurl} projectBaseUrl={projectBaseUrl} />
    </>
}

export default ProjectDetail;