import { useParams } from "react-router-dom"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import PropertySearch from "./property_search"
import { useState, useEffect, useRef, useContext, useCallback } from "react"
import { ApiService } from "../../Components/Services/apiservices"
import PropertyBox from "../../Components/Element/property_box"


const PropertyList = () => {
  const didMountRef = useRef(true);
  const [propertyList, setpropertyList] = useState([])
  const [imgUrl, setimgUrl] = useState('')
  const [isLoading, setisLoading] = useState(false);
  const { slug } = useParams()
  useEffect(() => {
    if (didMountRef.current) {



    }
    didMountRef.current = false;
  }, []);


  const getPropertylist = () => {
    const dataString = {
      property_list: slug,
    };
    ApiService.postData("", dataString).then((res) => {
      if (res.status == "success") {
        setpropertyList(res.data);
        setimgUrl(res.property_img_url)
        setTimeout(() => {
          setisLoading(false)
        }, 500)
      }
    }).catch(() => {
      setisLoading(false)
    });
  };
  return (<>
    <Header></Header>
    <PropertySearch></PropertySearch>
   
    <section className="flat-section-v6 flat-recommended flat-sidebar" data-wow-delay=".4s" data-wow-duration="2000ms">
      <div className="container">
        <div className="box-title-listing ">
          <h5 className="mt-4">Property Listing</h5>
          <div class="box-filter-tab">
            <ul class="nav-tab-filter" role="tablist">
              <li class="nav-tab-item" role="presentation">
                <a href="#gridLayout" class="nav-link-item active" data-bs-toggle="tab" aria-selected="true" role="tab"><i class="icon icon-grid"></i></a>
              </li>
              <li class="nav-tab-item" role="presentation">
                <a href="#listLayout" class="nav-link-item" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab"><i class="icon icon-list"></i></a>
              </li>
            </ul>
            <div class="nice-select list-page" tabindex="0"><span class="current">12 Per Page</span>
              <ul class="list">
                <li data-value="1" class="option">10 Per Page</li>
                <li data-value="2" class="option">11 Per Page</li>
                <li data-value="3" class="option selected">12 Per Page</li>
              </ul>
            </div>
            <div class="nice-select list-sort" tabindex="0"><span class="current">Sort by (Default)</span>
              <ul class="list">
                <li data-value="default" class="option selected">Sort by (Default)</li>
                <li data-value="new" class="option">Newest</li>
                <li data-value="old" class="option">Oldest</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6" >
            <div class="homeya-box md">
              <div class="archive-top">
                <div class="images-group">
                  <div class="images-style"><img src="https://proopify.studykee.com/csadmin/public/img/uploads/property/17226839853483.jpg" alt="img" /></div>
                  <div class="top">
                    <ul class="d-flex gap-8 flex-column">
                      <li class="flag-tag style-1">For Rent/Lease</li>
                    </ul>
                    <ul class="d-flex gap-4">
                      <li class="box-icon w-32">
                        <a href="#modalLogin" data-bs-toggle="modal"> <span class="icon icon-heart"></span></a>
                      </li>
                      <li class="box-icon w-32"><span class="icon icon-eye"></span></li>
                    </ul>
                  </div>
                  <div class="bottom"><span class="flag-tag style-2">Apartment</span></div>
                </div>
                <div class="content">
                  <div class="text-1 text-capitalize"><a href="/property/1-bhk-apartment-for-rent-in-kohinoor-residency-jaipur/000168" class="link">1 BHK Apartment for Rent in Kohinoor Residency, Jaipur</a></div>
                  <div class="desc">
                    <i class="fs-16 icon icon-mapPin"></i>
                    <p>OKAY PLUS SPACES, Malviya Nagar, Jaipur, Rajasthan, India</p>
                  </div>
                  <ul class="meta-list">
                    <li class="item"><i class="icon icon-bed"></i><span>1</span></li>
                    <li class="item"><i class="icon icon-bathtub"></i><span>2</span></li>
                    <li class="item"><i class="icon icon-ruler"></i><span>600 sq.ft.</span></li>
                  </ul>
                </div>
              </div>
              <div class="archive-bottom d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="h7 fw-7">$850</div>
                  <p class="text-variant-1 ms-1">/SqFT</p>
                </div>
              </div>
            </div>

          </div>
          <div className="col-xl-3 col-md-6" >
            <div class="homeya-box md">
              <div class="archive-top">
                <div class="images-group">
                  <div class="images-style"><img src="https://proopify.studykee.com/csadmin/public/img/uploads/property/17226839853483.jpg" alt="img" /></div>
                  <div class="top">
                    <ul class="d-flex gap-8 flex-column">
                      <li class="flag-tag style-1">For Rent/Lease</li>
                    </ul>
                    <ul class="d-flex gap-4">
                      <li class="box-icon w-32">
                        <a href="#modalLogin" data-bs-toggle="modal"> <span class="icon icon-heart"></span></a>
                      </li>
                      <li class="box-icon w-32"><span class="icon icon-eye"></span></li>
                    </ul>
                  </div>
                  <div class="bottom"><span class="flag-tag style-2">Apartment</span></div>
                </div>
                <div class="content">
                  <div class="text-1 text-capitalize"><a href="/property/1-bhk-apartment-for-rent-in-kohinoor-residency-jaipur/000168" class="link">1 BHK Apartment for Rent in Kohinoor Residency, Jaipur</a></div>
                  <div class="desc">
                    <i class="fs-16 icon icon-mapPin"></i>
                    <p>OKAY PLUS SPACES, Malviya Nagar, Jaipur, Rajasthan, India</p>
                  </div>
                  <ul class="meta-list">
                    <li class="item"><i class="icon icon-bed"></i><span>1</span></li>
                    <li class="item"><i class="icon icon-bathtub"></i><span>2</span></li>
                    <li class="item"><i class="icon icon-ruler"></i><span>600 sq.ft.</span></li>
                  </ul>
                </div>
              </div>
              <div class="archive-bottom d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="h7 fw-7">$850</div>
                  <p class="text-variant-1 ms-1">/SqFT</p>
                </div>
              </div>
            </div>

          </div>
          <div className="col-xl-3 col-md-6" >
            <div class="homeya-box md">
              <div class="archive-top">
                <div class="images-group">
                  <div class="images-style"><img src="https://proopify.studykee.com/csadmin/public/img/uploads/property/17226839853483.jpg" alt="img" /></div>
                  <div class="top">
                    <ul class="d-flex gap-8 flex-column">
                      <li class="flag-tag style-1">For Rent/Lease</li>
                    </ul>
                    <ul class="d-flex gap-4">
                      <li class="box-icon w-32">
                        <a href="#modalLogin" data-bs-toggle="modal"> <span class="icon icon-heart"></span></a>
                      </li>
                      <li class="box-icon w-32"><span class="icon icon-eye"></span></li>
                    </ul>
                  </div>
                  <div class="bottom"><span class="flag-tag style-2">Apartment</span></div>
                </div>
                <div class="content">
                  <div class="text-1 text-capitalize"><a href="/property/1-bhk-apartment-for-rent-in-kohinoor-residency-jaipur/000168" class="link">1 BHK Apartment for Rent in Kohinoor Residency, Jaipur</a></div>
                  <div class="desc">
                    <i class="fs-16 icon icon-mapPin"></i>
                    <p>OKAY PLUS SPACES, Malviya Nagar, Jaipur, Rajasthan, India</p>
                  </div>
                  <ul class="meta-list">
                    <li class="item"><i class="icon icon-bed"></i><span>1</span></li>
                    <li class="item"><i class="icon icon-bathtub"></i><span>2</span></li>
                    <li class="item"><i class="icon icon-ruler"></i><span>600 sq.ft.</span></li>
                  </ul>
                </div>
              </div>
              <div class="archive-bottom d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="h7 fw-7">$850</div>
                  <p class="text-variant-1 ms-1">/SqFT</p>
                </div>
              </div>
            </div>

          </div>
          <div className="col-xl-3 col-md-6" >
            <div class="homeya-box md">
              <div class="archive-top">
                <div class="images-group">
                  <div class="images-style"><img src="https://proopify.studykee.com/csadmin/public/img/uploads/property/17226839853483.jpg" alt="img" /></div>
                  <div class="top">
                    <ul class="d-flex gap-8 flex-column">
                      <li class="flag-tag style-1">For Rent/Lease</li>
                    </ul>
                    <ul class="d-flex gap-4">
                      <li class="box-icon w-32">
                        <a href="#modalLogin" data-bs-toggle="modal"> <span class="icon icon-heart"></span></a>
                      </li>
                      <li class="box-icon w-32"><span class="icon icon-eye"></span></li>
                    </ul>
                  </div>
                  <div class="bottom"><span class="flag-tag style-2">Apartment</span></div>
                </div>
                <div class="content">
                  <div class="text-1 text-capitalize"><a href="/property/1-bhk-apartment-for-rent-in-kohinoor-residency-jaipur/000168" class="link">1 BHK Apartment for Rent in Kohinoor Residency, Jaipur</a></div>
                  <div class="desc">
                    <i class="fs-16 icon icon-mapPin"></i>
                    <p>OKAY PLUS SPACES, Malviya Nagar, Jaipur, Rajasthan, India</p>
                  </div>
                  <ul class="meta-list">
                    <li class="item"><i class="icon icon-bed"></i><span>1</span></li>
                    <li class="item"><i class="icon icon-bathtub"></i><span>2</span></li>
                    <li class="item"><i class="icon icon-ruler"></i><span>600 sq.ft.</span></li>
                  </ul>
                </div>
              </div>
              <div class="archive-bottom d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="h7 fw-7">$850</div>
                  <p class="text-variant-1 ms-1">/SqFT</p>
                </div>
              </div>
            </div>

          </div>
          <div className="col-xl-3 col-md-6" >
            <div class="homeya-box md">
              <div class="archive-top">
                <div class="images-group">
                  <div class="images-style"><img src="https://proopify.studykee.com/csadmin/public/img/uploads/property/17226839853483.jpg" alt="img" /></div>
                  <div class="top">
                    <ul class="d-flex gap-8 flex-column">
                      <li class="flag-tag style-1">For Rent/Lease</li>
                    </ul>
                    <ul class="d-flex gap-4">
                      <li class="box-icon w-32">
                        <a href="#modalLogin" data-bs-toggle="modal"> <span class="icon icon-heart"></span></a>
                      </li>
                      <li class="box-icon w-32"><span class="icon icon-eye"></span></li>
                    </ul>
                  </div>
                  <div class="bottom"><span class="flag-tag style-2">Apartment</span></div>
                </div>
                <div class="content">
                  <div class="text-1 text-capitalize"><a href="/property/1-bhk-apartment-for-rent-in-kohinoor-residency-jaipur/000168" class="link">1 BHK Apartment for Rent in Kohinoor Residency, Jaipur</a></div>
                  <div class="desc">
                    <i class="fs-16 icon icon-mapPin"></i>
                    <p>OKAY PLUS SPACES, Malviya Nagar, Jaipur, Rajasthan, India</p>
                  </div>
                  <ul class="meta-list">
                    <li class="item"><i class="icon icon-bed"></i><span>1</span></li>
                    <li class="item"><i class="icon icon-bathtub"></i><span>2</span></li>
                    <li class="item"><i class="icon icon-ruler"></i><span>600 sq.ft.</span></li>
                  </ul>
                </div>
              </div>
              <div class="archive-bottom d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="h7 fw-7">$850</div>
                  <p class="text-variant-1 ms-1">/SqFT</p>
                </div>
              </div>
            </div>

          </div>
          <div className="col-xl-3 col-md-6" >
            <div class="homeya-box md">
              <div class="archive-top">
                <div class="images-group">
                  <div class="images-style"><img src="https://proopify.studykee.com/csadmin/public/img/uploads/property/17226839853483.jpg" alt="img" /></div>
                  <div class="top">
                    <ul class="d-flex gap-8 flex-column">
                      <li class="flag-tag style-1">For Rent/Lease</li>
                    </ul>
                    <ul class="d-flex gap-4">
                      <li class="box-icon w-32">
                        <a href="#modalLogin" data-bs-toggle="modal"> <span class="icon icon-heart"></span></a>
                      </li>
                      <li class="box-icon w-32"><span class="icon icon-eye"></span></li>
                    </ul>
                  </div>
                  <div class="bottom"><span class="flag-tag style-2">Apartment</span></div>
                </div>
                <div class="content">
                  <div class="text-1 text-capitalize"><a href="/property/1-bhk-apartment-for-rent-in-kohinoor-residency-jaipur/000168" class="link">1 BHK Apartment for Rent in Kohinoor Residency, Jaipur</a></div>
                  <div class="desc">
                    <i class="fs-16 icon icon-mapPin"></i>
                    <p>OKAY PLUS SPACES, Malviya Nagar, Jaipur, Rajasthan, India</p>
                  </div>
                  <ul class="meta-list">
                    <li class="item"><i class="icon icon-bed"></i><span>1</span></li>
                    <li class="item"><i class="icon icon-bathtub"></i><span>2</span></li>
                    <li class="item"><i class="icon icon-ruler"></i><span>600 sq.ft.</span></li>
                  </ul>
                </div>
              </div>
              <div class="archive-bottom d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="h7 fw-7">$850</div>
                  <p class="text-variant-1 ms-1">/SqFT</p>
                </div>
              </div>
            </div>

          </div>
          <div className="col-xl-3 col-md-6" >
            <div class="homeya-box md">
              <div class="archive-top">
                <div class="images-group">
                  <div class="images-style"><img src="https://proopify.studykee.com/csadmin/public/img/uploads/property/17226839853483.jpg" alt="img" /></div>
                  <div class="top">
                    <ul class="d-flex gap-8 flex-column">
                      <li class="flag-tag style-1">For Rent/Lease</li>
                    </ul>
                    <ul class="d-flex gap-4">
                      <li class="box-icon w-32">
                        <a href="#modalLogin" data-bs-toggle="modal"> <span class="icon icon-heart"></span></a>
                      </li>
                      <li class="box-icon w-32"><span class="icon icon-eye"></span></li>
                    </ul>
                  </div>
                  <div class="bottom"><span class="flag-tag style-2">Apartment</span></div>
                </div>
                <div class="content">
                  <div class="text-1 text-capitalize"><a href="/property/1-bhk-apartment-for-rent-in-kohinoor-residency-jaipur/000168" class="link">1 BHK Apartment for Rent in Kohinoor Residency, Jaipur</a></div>
                  <div class="desc">
                    <i class="fs-16 icon icon-mapPin"></i>
                    <p>OKAY PLUS SPACES, Malviya Nagar, Jaipur, Rajasthan, India</p>
                  </div>
                  <ul class="meta-list">
                    <li class="item"><i class="icon icon-bed"></i><span>1</span></li>
                    <li class="item"><i class="icon icon-bathtub"></i><span>2</span></li>
                    <li class="item"><i class="icon icon-ruler"></i><span>600 sq.ft.</span></li>
                  </ul>
                </div>
              </div>
              <div class="archive-bottom d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="h7 fw-7">$850</div>
                  <p class="text-variant-1 ms-1">/SqFT</p>
                </div>
              </div>
            </div>

          </div>
          <div className="col-xl-3 col-md-6" >
            <div class="homeya-box md">
              <div class="archive-top">
                <div class="images-group">
                  <div class="images-style"><img src="https://proopify.studykee.com/csadmin/public/img/uploads/property/17226839853483.jpg" alt="img" /></div>
                  <div class="top">
                    <ul class="d-flex gap-8 flex-column">
                      <li class="flag-tag style-1">For Rent/Lease</li>
                    </ul>
                    <ul class="d-flex gap-4">
                      <li class="box-icon w-32">
                        <a href="#modalLogin" data-bs-toggle="modal"> <span class="icon icon-heart"></span></a>
                      </li>
                      <li class="box-icon w-32"><span class="icon icon-eye"></span></li>
                    </ul>
                  </div>
                  <div class="bottom"><span class="flag-tag style-2">Apartment</span></div>
                </div>
                <div class="content">
                  <div class="text-1 text-capitalize"><a href="/property/1-bhk-apartment-for-rent-in-kohinoor-residency-jaipur/000168" class="link">1 BHK Apartment for Rent in Kohinoor Residency, Jaipur</a></div>
                  <div class="desc">
                    <i class="fs-16 icon icon-mapPin"></i>
                    <p>OKAY PLUS SPACES, Malviya Nagar, Jaipur, Rajasthan, India</p>
                  </div>
                  <ul class="meta-list">
                    <li class="item"><i class="icon icon-bed"></i><span>1</span></li>
                    <li class="item"><i class="icon icon-bathtub"></i><span>2</span></li>
                    <li class="item"><i class="icon icon-ruler"></i><span>600 sq.ft.</span></li>
                  </ul>
                </div>
              </div>
              <div class="archive-bottom d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="h7 fw-7">$850</div>
                  <p class="text-variant-1 ms-1">/SqFT</p>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </section>
    <Footer></Footer>
  </>)
}

export default PropertyList