import { useState, useEffect, useRef, useContext, useCallback } from "react"
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiservices";
import { validEmail, validNumber } from "../../Components/Element/Regex";
import constant from "../../Components/Services/constant";
import PropertyBox from "../../Components/Element/property_box";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Loader from "react-js-loader";

function PropertyDetail() {
    const navigate = useNavigate()
    const [enquiryUserdetail, setenquiryUserdetail] = useState({
        enquired_user_name: "",
        enquired_user_email: "",
        enquired_user_mobile: "",
        enquired_message: "",
    })
    const [postPropertyData, setpostPropertyData] = useState({});
    const [isLoading, setisLoading] = useState(false);
    const [propertyTitle, setpropertyTitle] = useState('')
    const [brochureurl, setbrochureurl] = useState('')
    const [amenityimgurl, setamenityimgurl] = useState('')
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [spinnerloading, setspinnerloading] = useState('')
    const [imgUrl, setimgUrl] = useState('')
    const [floorplanimgUrl, setfloorplanimgUrl] = useState('')
    const [galleryItems, setGalleryItems] = useState([]);
    const [featuredpropertiesdata, setfeaturedPropertiesdata] = useState([])
    const didMountRef = useRef(true);
    const session_token = localStorage.getItem('USER_TOKEN')
    const [activeIndex, setActiveIndex] = useState(0);

    // Function to handle toggling the collapse state
    const handleToggle = (index) => {
        setActiveIndex(index === activeIndex ? -1 : index); // Toggle the current index or close if it's already open
    };
    useEffect(() => {
        if (didMountRef.current) {
            if (id) {
                getPropertydetail();
                getfeaturedproperty()

            }
            const session_token = localStorage.getItem('USER_TOKEN')

            if (session_token && session_token !== null) {
                getUserdata()
            }

        }
        didMountRef.current = false;
    }, []);
    const { id } = useParams()
    const getPropertydetail = () => {
        const dataString = {
            property_url_id: id,
        };
        ApiService.postData("featuredpropertydetails", dataString).then((res) => {
            if (res.status == "success") {
                setpostPropertyData(res.data);
                setimgUrl(res.property_img_url)
                setpropertyTitle(res.property_title)
                setbrochureurl(res.brochure_url)
                setamenityimgurl(res.amenities_img_url)
                setfloorplanimgUrl(res.floorplan_url)

                const galleryItems = [
                    {
                        original:
                            res.data.property_image != null
                                ? res.property_img_url + res.data.property_image
                                : constant.DEFAULT_IMAGE
                    },
                ];
                res.data.gallery?.map((value) => {
                    galleryItems.push({
                        original:
                            value.pg_image != null ? value.pg_image : constant.DEFAULT_IMAGE,
                    });
                });
        setGalleryItems(galleryItems);
            } else if (
                res.status == "session_expired" &&
                res.message == "Session expired"
            ) {
                localStorage.removeItem("USER_TOKEN");

                navigate("/");
            }
        });
    };

    const getfeaturedproperty = () => {
        setisLoading(true)
        ApiService.fetchData('featuredpropertylist ').then((res) => {
            if (res.status == 'success') {
                setimgUrl(res.property_img_url)
                setfeaturedPropertiesdata(res.resPropertyData)
                setTimeout(() => {
                    setisLoading(false)
                }, 500)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }



    const addtofav = () => {
        setspinnerloading(postPropertyData.property_id)
        const dataString = {
            property_id: postPropertyData.property_id,
        };
        ApiService.postData("addtofav", dataString).then((res) => {
            if (res.data.status == "success") {
                setspinnerloading('')
                setTimeout(() => {
                    var element = document.getElementById("wishlisticon" + postPropertyData.property_id);
                    element.classList.remove("icon-heart-fill", "icon-heart");
                    if (res.data.notification === "ri-heart-fil") {
                        element.classList.add('icon-heart-fill');
                    } else {
                        element.classList.add('icon-heart');
                    }
                }, 100)
            } else {
                setspinnerloading('')
            }
        }).catch(() => {
            setspinnerloading('')
        });
    };


    const onInputChange = (e) => {
        setErrorMessage("");
        setSuccessMessage("");
        const { name, value } = e.target;
        setenquiryUserdetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== "") {
            e.target.style.border = "";
        }
    };


    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const words = postPropertyData?.property_desc ? postPropertyData?.property_desc.split(' ') : [];
    const isLongDescription = words.length > 250;
    const shortDescription = words.slice(0, 250).join(' ');
    const submitEnquire = (e) => {
        e.preventDefault()
        let counter = 0;
        const myElements = document.getElementsByClassName("enquirerequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === "") {

                myElements[i].style.border = "1px solid red";
                counter++;
            } else {
                myElements[i].style.border = "";
            }
        }

        if (counter == 0) {
            if (!validNumber.test(enquiryUserdetail.enquired_user_mobile)) {
                setErrorMessage("Please enter valid Mobile Number");
                return false;
            }
            if (!validEmail.test(enquiryUserdetail.enquired_user_email)) {
                setErrorMessage("Please Enter Valid Email");
                return false;
            }
            setisLoading(true)
            const dataString = {
                enquiry_name: enquiryUserdetail.enquired_user_name,
                enquiry_email: enquiryUserdetail.enquired_user_email,
                enquiry_mobile: enquiryUserdetail.enquired_user_mobile,
                enquiry_property_id: id,
                enquiry_message: enquiryUserdetail.enquired_message

            };
            ApiService.postData("propertyenquiry", dataString).then((res) => {
                if (res.status == "success") {
                    setSuccessMessage(res.message);
                    setisLoading(false)
                } else {
                    setErrorMessage(res.message);
                    setisLoading(false)
                }
            }).catch(() => {
                setisLoading(false)
            });
        }
    };


    useEffect(() => {
        function initMap() {
            if (!window.google) {
                console.error('Google Maps API is not available.');
                return;
            }


            var location = { lat: Number(postPropertyData?.property_lat), lng: Number(postPropertyData?.property_long) };
            console.log(location, 'location')
            var map = new window.google.maps.Map(document.getElementById('map-single'), {
                zoom: 16,
                center: location,
                scrollwheel: true,
            });

            new window.google.maps.Marker({
                position: location,
                map: map,
            });
        }
        if (!window.google) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDuBIgdGc_4dGE2v4gA3DL0efxwHejtY1A&callback=initMap&libraries=places`;
            script.async = true;
            script.defer = true;
            window.initMap = initMap;
            document.head.appendChild(script);
            return () => {
                document.head.removeChild(script);
            };
        } else {
            initMap();
        }
    }, [postPropertyData]);

    const getUserdata = () => {
        ApiService.fetchData("getuserdata")
            .then((res) => {
                if (res.status == "success") {
                    setenquiryUserdetail({
                        enquired_user_name: res.rowUserData.user_name,
                        enquired_user_email: res.rowUserData.user_email,
                        enquired_user_mobile: res.rowUserData.user_mobile,
                    });
                } else if (
                    res.status == "session_expired" &&
                    res.message == "Session expired"
                ) {
                    localStorage.removeItem("USER_TOKEN");
                    localStorage.removeItem("TEMP_USER_TOKEN");
                    navigate("/");
                } else {
                }
            })
            .catch((error) => { });
    };

    return (
        <>
            <div id="wrapper">
                <div id="pagee" className="clearfix">

                    <Header />
                    <section className="flat-location flat-slider-detail-v1">
                        <Swiper
                            spaceBetween={15}
                            slidesPerView={2}
                            loop={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="swiper tf-sw-location">

                            {galleryItems && galleryItems.map((value, index) => (
                                <SwiperSlide key={`slide-${index}`}>
                                    <a href={value.original || constant.DEFAULT_IMAGE} data-fancybox="gallery" className="box-imgage-detail d-block">
                                        <img
                                            src={value.original || constant.DEFAULT_IMAGE}
                                            alt="img-property"
                                        />
                                    </a>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                    </section>

                    <section className="flat-section pt-0 flat-property-detail">
                        <div className="container">
                            <div className="header-property-detail">
                                <div className="content-top d-flex justify-content-between align-items-center">
                                    <div className="box-name">
                                        {postPropertyData.property_for ? <a href="javascript:void(0)" className="flag-tag primary">For {postPropertyData.property_for == 1 ? 'Sale' : postPropertyData.property_for == 2 ? "Rent/Lease" : ''}</a> : ''}
                                        {propertyTitle ? <h4 className="title link">{propertyTitle}</h4> : postPropertyData?.property_name ? <h4 className="title link">{postPropertyData?.property_name}</h4> : ''}

                                    </div>
                                    {postPropertyData.property_for == 2 ? <>
                                        <div className="box-price d-flex align-items-center">
                                            <h4>₹{postPropertyData.property_expected_price}</h4>
                                            <span className="body-1 text-variant-1">/month</span>
                                        </div>
                                    </> : <>
                                        <div className="box-price d-flex align-items-center">
                                            <h4>₹{postPropertyData.property_expected_price}</h4>
                                            <span className="body-1 text-variant-1">/month</span>
                                        </div>
                                    </>}
                                </div>
                                <div className="content-bottom">
                                    <div className="info-box">
                                        <div className="label">FEATUREs:</div>
                                        <ul className="meta">
                                            {postPropertyData?.property_no_bedroom && (
                                                <li className="meta-item"><span className="icon icon-bed"></span> {postPropertyData?.property_no_bedroom} Bedroom</li>
                                            )}
                                            {postPropertyData?.property_no_bathroom && (
                                                <li className="meta-item"><span className="icon icon-bathtub"></span> {postPropertyData?.property_no_bathroom} Bathroom</li>
                                            )}
                                            {postPropertyData?.property_carpet_area && (
                                                <li className="meta-item"><span className="icon icon-ruler"></span> {postPropertyData?.property_carpet_area} {postPropertyData?.property_carpet_unit}</li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="info-box">
                                        <div className="label">LOCATION:</div>
                                        <p className="meta-item"><span className="icon icon-mapPin"></span>{postPropertyData?.property_address}</p>
                                    </div>
                                    <ul className="icon-box">
                                        {session_token && session_token !== null ? <>
                                            {postPropertyData?.userFavorites?.ufp_id > 0 ?
                                                <>
                                                    <li onClick={(e) =>
                                                        addtofav()
                                                    }>
                                                        <a href="javascript:void(0)" className="item" >
                                                            {spinnerloading == postPropertyData.property_id ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> :
                                                                <span className="icon icon-heart-fill" id={
                                                                    "wishlisticon" +
                                                                    postPropertyData.property_id
                                                                }></span>}
                                                        </a></li>

                                                </> : <><li onClick={(e) =>
                                                    addtofav()
                                                }>
                                                    <a href="javascript:void(0)" className="item">
                                                        {spinnerloading == postPropertyData.property_id ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> :
                                                            <span className="icon icon-heart" id={
                                                                "wishlisticon" +
                                                                postPropertyData.property_id
                                                            }></span>}

                                                    </a></li></>
                                            }

                                        </> : <>  <li><a href="#modalLogin" data-bs-toggle="modal" className="item"><span className="icon icon-heart"></span></a></li></>}
                                    </ul>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8">
                                    {postPropertyData?.property_desc && (
                                        <div className="single-property-element single-property-desc">
                                            <div className="h7 title fw-7">Description</div>
                                            <p className="body-2 text-variant-1" >
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: isExpanded || !isLongDescription ? postPropertyData?.property_desc : `${shortDescription}...`
                                                    }}
                                                />
                                            </p>
                                            {isLongDescription && (
                                                <a href="javascript:void(0)" className="btn-view" onClick={toggleExpansion}>
                                                    <span className="text">{isExpanded ? 'View Less' : 'View More'}</span>
                                                </a>
                                            )}
                                        </div>
                                    )}
                                    <div className="single-property-element single-property-overview">
                                        <div className="h7 title fw-7">Overview</div>
                                        <ul className="info-box">
                                            {postPropertyData?.property_category_name && (
                                                <li className="item">
                                                    <a href="javascript:void(0)" className="box-icon w-52"><i className="icon icon-arrLeftRight"></i></a>
                                                    <div className="content">
                                                        <span className="label">Type:</span>
                                                        <span>{postPropertyData?.property_category_name}</span>
                                                    </div>
                                                </li>
                                            )}
                                            {postPropertyData?.property_no_bedroom && (
                                                <li className="item">
                                                    <a href="javascript:void(0)" className="box-icon w-52"><i className="icon icon-bed"></i></a>
                                                    <div className="content">
                                                        <span className="label">Bedrooms:</span>
                                                        <span>{postPropertyData?.property_no_bedroom} Rooms</span>
                                                    </div>
                                                </li>
                                            )}
                                            {postPropertyData?.property_no_bathroom && (
                                                <li className="item">
                                                    <a href="javascript:void(0)" className="box-icon w-52"><i className="icon icon-bathtub"></i></a>
                                                    <div className="content">
                                                        <span className="label">Bathrooms:</span>
                                                        <span>{postPropertyData?.property_no_bathroom} Rooms</span>
                                                    </div>
                                                </li>
                                            )}
                                            {postPropertyData?.property_no_balconies && (
                                                <li className="item">
                                                    <a href="#" className="box-icon w-52"><i className="icon icon-garage"></i></a>
                                                    <div className="content">
                                                        <span className="label">Balconies:</span>
                                                        <span>{postPropertyData?.property_no_balconies}</span>
                                                    </div>
                                                </li>
                                            )}
                                            {postPropertyData?.property_carpet_area && (
                                                <li className="item">
                                                    <a href="javascript:void(0)" className="box-icon w-52"><i className="icon icon-ruler"></i></a>
                                                    <div className="content">
                                                        <span className="label">Size:</span>
                                                        <span>{postPropertyData?.property_carpet_area} {postPropertyData?.property_carpet_unit}</span>
                                                    </div>
                                                </li>
                                            )}

                                            {postPropertyData?.property_year_built && (
                                                <li className="item">
                                                    <a href="javascript:void(0)" className="box-icon w-52"><i className="icon icon-hammer"></i></a>
                                                    <div className="content">
                                                        <span className="label">Year Built:</span>
                                                        <span>{postPropertyData.property_year_built.split('-')[0]}</span>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    {postPropertyData.property_video || postPropertyData?.property_video_link ? <>
                                        <div className="single-property-element single-property-video">
                                            <div className="h7 title fw-7">Video</div>
                                            <div className="img-video">
                                                <img src="images/banner/img-video.jpg" alt="img-video" />
                                                {postPropertyData?.property_video_link ? <>
                                                    <a href={postPropertyData?.property_video_link} data-fancybox="gallery2" className="btn-video"> <span className="icon icon-play"></span></a>
                                                </> : <>
                                                    <a href={postPropertyData.property_video} data-fancybox="gallery2" className="btn-video"> <span className="icon icon-play"></span></a>
                                                </>}
                                            </div>
                                        </div>
                                    </> : ''}
                                    <div className="single-property-element single-property-info">
                                        <div className="h7 title fw-7">Property Details</div>
                                        <div className="row">
                                            {postPropertyData.property_furnish && (
                                                <div className="col-md-6">
                                                    <div className="inner-box">
                                                        <span className="label">Furnished Status:</span>
                                                        <div className="content fw-7">{postPropertyData.property_furnish === 1 ? "Furnished" : postPropertyData.property_furnish == 2 ? "Semi-Furnished" : postPropertyData.property_furnish == 3 ? "Un-Furnished" : ''}</div>
                                                    </div>
                                                </div>
                                            )}
                                            {postPropertyData.property_availabilty_status && (
                                                <div className="col-md-6">
                                                    <div className="inner-box">
                                                        <span className="label">Availablity Status:</span>
                                                        <div className="content fw-7">{postPropertyData.property_availabilty_status == 1 ? "Ready To Move" : postPropertyData.property_availabilty_status == 2 ? "Under Construction" : ''}</div>
                                                    </div>
                                                </div>
                                            )}
                                            {postPropertyData.property_ownership && (
                                                <div className="col-md-6">
                                                    <div className="inner-box">
                                                        <span className="label">Ownership:</span>
                                                        <div className="content fw-7">{postPropertyData.property_ownership == 1 ? "Freehold" : postPropertyData.property_ownership == 2 ? "Leasehold" : postPropertyData.property_ownership == 3 ? "Co-Operative Society" : postPropertyData.property_ownership == 4 ? "Power Of Attorney" : ''}</div>
                                                    </div>
                                                </div>
                                            )}

                                            {postPropertyData.property_on_floor && (
                                                <div className="col-md-6">
                                                    <div className="inner-box">
                                                        <span className="label">Property on floor:</span>
                                                        <div className="content fw-7">{postPropertyData.property_on_floor}</div>
                                                    </div>
                                                </div>
                                            )}
                                            {postPropertyData.property_year_built && (
                                                <div className="col-md-6">
                                                    <div className="inner-box">
                                                        <span className="label">Year built:</span>
                                                        <div className="content fw-7">{postPropertyData.property_year_built}</div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    {postPropertyData?.selected_amenities && postPropertyData?.selected_amenities.length > 0 && (
                                        <div className="single-property-element single-property-feature">
                                            <div className="h7 title fw-7">Amenities and Features</div>
                                            <div className="wrap-feature">
                                                {postPropertyData.selected_amenities.map((item, index) => (
                                                    <div className="box-feature" key={index}>
                                                        <div className="fw-7">{item?.name}:</div>
                                                        {item?.children?.length > 0 && (
                                                            <ul>
                                                                {item.children.map((childitem, childindex) => (
                                                                    <li className="feature-item" key={childindex}>
                                                                        <img
                                                                            src={childitem?.image ? `${amenityimgurl}${childitem.image}` : constant.DEFAULT_IMAGE}
                                                                            alt={childitem?.name || 'Amenity Image'}
                                                                            style={{ width: '17px', height: '17px' }}
                                                                        />
                                                                        {childitem?.name}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    <div className="single-property-element single-property-map">
                                        <div className="h7 title fw-7">Map</div>
                                        <div id="map-single" className="map-single" data-map-zoom="16" data-map-scroll="true"></div>
                                        <ul className="info-map">
                                            {postPropertyData?.property_address && (
                                                <li>
                                                    <div className="fw-7">Address</div>
                                                    <span className="mt-4 text-variant-1">{postPropertyData?.property_address}</span>
                                                </li>
                                            )}

                                        </ul>
                                    </div>
                                    {postPropertyData?.floorplan && postPropertyData?.floorplan.length > 0 ? <>
                                        <div className="single-property-element single-property-floor">
                                            <div className="h7 title fw-7">Floor plans</div>
                                            <ul className="box-floor" id="parent-floor">
                                                {postPropertyData.floorplan.map((item, index) => (
                                                    <li className="floor-item" key={index}>
                                                        <div
                                                            className="floor-header"
                                                            data-bs-target={`#floor-${index + 1}`}
                                                            data-bs-toggle="collapse"
                                                            aria-expanded={activeIndex === index}
                                                            aria-controls={`floor-${index + 1}`}
                                                            onClick={() => handleToggle(index)}
                                                        >
                                                            <div className="inner-left">
                                                                <i className="icon icon-arr-r"></i>
                                                                <span className="fw-7">{item?.pfp_title}</span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            id={`floor-${index + 1}`}
                                                            className={`collapse ${activeIndex === index ? 'show' : ''}`}
                                                            data-bs-parent="#parent-floor"
                                                        >
                                                            <div className="faq-body">
                                                                <div className="box-img">
                                                                    <img
                                                                        src={item.pfp_image ? `${floorplanimgUrl}${item.pfp_image}` : constant.DEFAULT_IMAGE}
                                                                        alt="img-floor"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}

                                            </ul>
                                        </div>
                                    </> : ''}
                                    {postPropertyData?.brochure && postPropertyData?.brochure?.length > 0 ? <>

                                        <div className="single-property-element single-property-attachments">
                                            <div className="h7 title fw-7">Brochure</div>
                                            <div className="row">
                                                {postPropertyData?.brochure?.map((item, index) => {
                                                    return (<>
                                                        <div className="col-sm-6">
                                                            <a href={`${brochureurl}${item?.pb_file}`} target="_blank" className="attachments-item" download={`Brochure-${index + 1}.pdf`}>
                                                                <div className="box-icon w-60">
                                                                    <img src="/images/home/file-1.png" alt="file" />
                                                                </div>
                                                                <span>Brochure.pdf</span>
                                                                <i className="icon icon-download"></i>
                                                            </a>
                                                        </div>
                                                    </>)
                                                })}
                                            </div>
                                        </div>
                                    </> : ''}
                                </div>
                                <div className="col-lg-4">
                                    <div className="widget-sidebar fixed-sidebar wrapper-sidebar-right">
                                        <div className="widget-box single-property-contact bg-surface">
                                            <div className="h7 title fw-7">Contact Sellers</div>
                                            <div className="box-avatar">
                                                <div className="avatar avt-100 round">
                                                    <img src={postPropertyData?.user?.user_img ? postPropertyData?.user?.user_img : '/images/user.png'} alt="avatar" />
                                                </div>
                                                <div className="info">
                                                    <div className="text-1 name">{postPropertyData?.user?.user_name}</div>
                                                    <span>{postPropertyData?.user?.user_mobile} {postPropertyData?.user?.user_email}</span>
                                                </div>
                                            </div>
                                            {errorMessage && (
                                                <div className="alert alert-danger" role="alert">
                                                    {errorMessage}
                                                </div>
                                            )}
                                            {successMessage && (
                                                <div className="alert alert-success" role="alert">
                                                    {successMessage}
                                                </div>
                                            )}
                                            <form action="#" className="contact-form">
                                                <div className="ip-group">
                                                    <label for="fullname">Full Name:</label>
                                                    <input type="text" placeholder="Jony Dane" className="form-control enquirerequired" value={enquiryUserdetail?.enquired_user_name} name="enquired_user_name" onChange={(e) => { onInputChange(e) }} />
                                                </div>
                                                <div className="ip-group">
                                                    <label for="phone">Phone Number:</label>
                                                    <input type="text" placeholder="ex 0123456789" className="form-control enquirerequired" value={enquiryUserdetail?.enquired_user_mobile} name="enquired_user_mobile" onChange={(e) => { onInputChange(e) }} />
                                                </div>
                                                <div className="ip-group">
                                                    <label for="email">Email Address:</label>
                                                    <input type="text" placeholder="themesflat@gmail.com" className="form-control enquirerequired" name="enquired_user_email" value={enquiryUserdetail?.enquired_user_email} onChange={(e) => { onInputChange(e) }} />
                                                </div>
                                                <div className="ip-group">
                                                    <label for="message">Your Message:</label>
                                                    <textarea id="comment-message" name="message" rows="4" tabindex="4" className="enquirerequired"
                                                        placeholder="Message" aria-required="true" onChange={(e) => { onInputChange(e) }}></textarea>
                                                </div>
                                                <button className="tf-btn primary w-100" onClick={(e) => { submitEnquire(e) }}>Send Message</button>
                                            </form>
                                        </div>
                                        {/* <div className="flat-tab flat-tab-form widget-filter-search widget-box bg-surface">
                                            <div className="h7 title fw-7">Search</div>
                                            <ul className="nav-tab-form" role="tablist">
                                                <li className="nav-tab-item" role="presentation">
                                                    <a href="#forRent" className="nav-link-item active" data-bs-toggle="tab">For Rent</a>
                                                </li>
                                                <li className="nav-tab-item" role="presentation">
                                                    <a href="#forSale" className="nav-link-item" data-bs-toggle="tab">For Sale</a>
                                                </li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane fade active show" role="tabpanel">
                                                    <div className="form-sl">
                                                        <form method="post">
                                                            <div className="wd-filter-select">
                                                                <div className="inner-group inner-filter">
                                                                    <div className="form-style">
                                                                        <label className="title-select">Keyword</label>
                                                                        <input type="text" className="form-control" placeholder="Search Keyword." value="" name="s" title="Search for" required="" />
                                                                    </div>
                                                                    <div className="form-style">
                                                                        <label className="title-select">Location</label>
                                                                        <div className="group-ip ip-icon">
                                                                            <input type="text" className="form-control" placeholder="Search Location" value="" name="s" title="Search for" required="" />
                                                                            <a href="#" className="icon-right icon-location"></a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-style">
                                                                        <label className="title-select">Type</label>
                                                                        <div className="group-select">
                                                                            <div className="nice-select" tabindex="0"><span className="current">All</span>
                                                                                <ul className="list">
                                                                                    <li data-value className="option selected">All</li>
                                                                                    <li data-value="villa" className="option">Villa</li>
                                                                                    <li data-value="studio" className="option">Studio</li>
                                                                                    <li data-value="office" className="option">Office</li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-style box-select">
                                                                        <label className="title-select">Rooms</label>
                                                                        <div className="nice-select" tabindex="0"><span className="current">2</span>
                                                                            <ul className="list">
                                                                                <li data-value="2" className="option">1</li>
                                                                                <li data-value="2" className="option selected">2</li>
                                                                                <li data-value="3" className="option">3</li>
                                                                                <li data-value="4" className="option">4</li>
                                                                                <li data-value="5" className="option">5</li>
                                                                                <li data-value="6" className="option">6</li>
                                                                                <li data-value="7" className="option">7</li>
                                                                                <li data-value="8" className="option">8</li>
                                                                                <li data-value="9" className="option">9</li>
                                                                                <li data-value="10" className="option">10</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-style box-select">
                                                                        <label className="title-select">Bathrooms</label>
                                                                        <div className="nice-select" tabindex="0"><span className="current">4</span>
                                                                            <ul className="list">
                                                                                <li data-value="all" className="option">All</li>
                                                                                <li data-value="1" className="option">1</li>
                                                                                <li data-value="2" className="option">2</li>
                                                                                <li data-value="3" className="option">3</li>
                                                                                <li data-value="4" className="option selected">4</li>
                                                                                <li data-value="5" className="option">5</li>
                                                                                <li data-value="6" className="option">6</li>
                                                                                <li data-value="7" className="option">7</li>
                                                                                <li data-value="8" className="option">8</li>
                                                                                <li data-value="9" className="option">9</li>
                                                                                <li data-value="10" className="option">10</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-style box-select">
                                                                        <label className="title-select">Bedrooms</label>
                                                                        <div className="nice-select" tabindex="0"><span className="current">4</span>
                                                                            <ul className="list">
                                                                                <li data-value="1" className="option">All</li>
                                                                                <li data-value="1" className="option">1</li>
                                                                                <li data-value="2" className="option">2</li>
                                                                                <li data-value="3" className="option">3</li>
                                                                                <li data-value="4" className="option selected">4</li>
                                                                                <li data-value="5" className="option">5</li>
                                                                                <li data-value="6" className="option">6</li>
                                                                                <li data-value="7" className="option">7</li>
                                                                                <li data-value="8" className="option">8</li>
                                                                                <li data-value="9" className="option">9</li>
                                                                                <li data-value="10" className="option">10</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-style widget-price">
                                                                        <div className="box-title-price">
                                                                            <span className="title-price">Price Range</span>
                                                                            <div className="caption-price">
                                                                                <span>from</span>
                                                                                <span id="slider-range-value1" className="fw-7"></span>
                                                                                <span>to</span>
                                                                                <span id="slider-range-value2" className="fw-7"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div id="slider-range"></div>
                                                                        <div className="slider-labels">
                                                                            <input type="hidden" name="min-value" value="" />
                                                                            <input type="hidden" name="max-value" value="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-style widget-price wd-price-2">
                                                                        <div className="box-title-price">
                                                                            <span className="title-price">Size Range</span>
                                                                            <div className="caption-price">
                                                                                <span>from</span>
                                                                                <span id="slider-range-value01" className="fw-7"></span>
                                                                                <span>to</span>
                                                                                <span id="slider-range-value02" className="fw-7"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div id="slider-range2"></div>
                                                                        <div className="slider-labels">
                                                                            <input type="hidden" name="min-value2" value="" />
                                                                            <input type="hidden" name="max-value2" value="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-style btn-show-advanced">
                                                                        <a className="filter-advanced pull-right">
                                                                            <span className="icon icon-faders"></span>
                                                                            <span className="text-advanced">Show Advanced</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="form-style wd-amenities">
                                                                        <div className="group-checkbox">
                                                                            <div className="text-1">Amenities:</div>
                                                                            <div className="group-amenities">
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb1" checked />
                                                                                    <label for="cb1" className="text-cb-amenities">Air Condition</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb2" />
                                                                                    <label for="cb2" className="text-cb-amenities">Disabled Access</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb3" />
                                                                                    <label for="cb3" className="text-cb-amenities">Ceiling Height</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb4" checked />
                                                                                    <label for="cb4" className="text-cb-amenities">Floor</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb5" />
                                                                                    <label for="cb5" className="text-cb-amenities">Heating</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb6" />
                                                                                    <label for="cb6" className="text-cb-amenities">Renovation</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb7" />
                                                                                    <label for="cb7" className="text-cb-amenities">Window Type</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb8" />
                                                                                    <label for="cb8" className="text-cb-amenities">Cable TV</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb9" checked />
                                                                                    <label for="cb9" className="text-cb-amenities">Elevator</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb10" />
                                                                                    <label for="cb10" className="text-cb-amenities">Furnishing</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb11" />
                                                                                    <label for="cb11" className="text-cb-amenities">Intercom</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb12" />
                                                                                    <label for="cb12" className="text-cb-amenities">Security</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb13" />
                                                                                    <label for="cb13" className="text-cb-amenities">Search property</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb14" />
                                                                                    <label for="cb14" className="text-cb-amenities">Ceiling Height</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb15" />
                                                                                    <label for="cb15" className="text-cb-amenities">Fence</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb16" />
                                                                                    <label for="cb16" className="text-cb-amenities">Fence</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb17" checked />
                                                                                    <label for="cb17" className="text-cb-amenities">Garage</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb18" />
                                                                                    <label for="cb18" className="text-cb-amenities">Parking</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb19" />
                                                                                    <label for="cb19" className="text-cb-amenities">Swimming Pool</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb20" />
                                                                                    <label for="cb20" className="text-cb-amenities">Construction Year</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb21" />
                                                                                    <label for="cb21" className="text-cb-amenities">Fireplace</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb22" />
                                                                                    <label for="cb22" className="text-cb-amenities">Garden</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb23" />
                                                                                    <label for="cb23" className="text-cb-amenities">Pet Friendly</label>
                                                                                </fieldset>
                                                                                <fieldset className="amenities-item">
                                                                                    <input type="checkbox" className="tf-checkbox style-1" id="cb24" />
                                                                                    <label for="cb24" className="text-cb-amenities">WiFi</label>
                                                                                </fieldset>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="form-style btn-hide-advanced">
                                                                        <a className="filter-advanced pull-right">
                                                                            <span className="icon icon-faders"></span>
                                                                            <span className="text-advanced">Hide Advanced</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="form-style">
                                                                        <button type="submit" className="tf-btn primary" href="#">Find Properties</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                            </div>
                                        </div> */}
                                        <div className="widget-box single-property-whychoose bg-surface">
                                            <div className="h7 title fw-7">Why Choose Us?</div>
                                            <ul className="box-whychoose">
                                                <li className="item-why">
                                                    <i className="icon icon-secure"></i>
                                                    Secure Booking
                                                </li>
                                                <li className="item-why">
                                                    <i className="icon icon-guarantee"></i>
                                                    Best Price Guarantee
                                                </li>
                                                <li className="item-why">
                                                    <i className="icon icon-booking"></i>
                                                    Easy Booking Process
                                                </li>
                                                <li className="item-why">
                                                    <i className="icon icon-support"></i>
                                                    Available Support 24/7
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </section>
                    {featuredpropertiesdata && featuredpropertiesdata?.length > 0 ? <>
                        <section className="flat-section pt-0 flat-latest-property">
                            <div className="container">
                                <div className="box-title">
                                    <div className="text-subtitle text-primary">Featured properties</div>
                                    <h4 className="mt-4">The Most Recent Estate</h4>
                                </div>
                                <Swiper
                                    spaceBetween={15}
                                    slidesPerView={3}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    modules={[Autoplay, Pagination, Navigation]}
                                >
                                    {featuredpropertiesdata?.map((item, index) => {
                                        return (<>

                                            <SwiperSlide key={index}>
                                                <PropertyBox propertydetail={item} imgUrl={imgUrl}></PropertyBox>
                                            </SwiperSlide>
                                        </>)
                                    })}
                                </Swiper>
                            </div>
                        </section>
                    </> : ''}
                    <Footer />

                </div>
            </div>
        </>

    )
}

export default PropertyDetail