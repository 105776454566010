import Footer from "../../Components/Footer"
import Header from "../../Components/Header"

const Profile=()=>{
    return(<>
    <Header></Header>
    <p>Profile</p>
    <Footer></Footer>
    </>)
}

export default Profile