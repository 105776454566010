import { useState, useEffect, useRef, useContext, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import constant from "../../Components/Services/constant"
import { ApiService } from "../../Components/Services/apiservices"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';



const HomeCities = () => {
    const [featuredcitiesdata, setfeaturedcities] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [imgUrl, setimgUrl] = useState('')
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getfeaturedcitiesproperty()
        }
        didMountRef.current = false
    }, [])

    const getfeaturedcitiesproperty = () => {
        setIsLoading(true)
        ApiService.fetchData('featuredcity').then((res) => {
            if (res.status == 'success') {
                setfeaturedcities(res.citiesData)

                setimgUrl(res.city_image_path)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500)
            }
            else {
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }
    const slidercityRef = useRef(null);
    const handlePrev = useCallback(() => {
        if (!slidercityRef.current) return;
        slidercityRef.current.swiper.slidePrev();
    }, []);
  
    const handleNext = useCallback(() => {
        if (!slidercityRef.current) return;
        slidercityRef.current.swiper.slideNext();
    }, [])
    return (<>
        {featuredcitiesdata && featuredcitiesdata.length > 0 ?
            <>

                <section className="flat-section-v3 flat-location bg-surface">
                <div className="container-full">
                <div className="box-title text-center wow fadeInUpSmall" data-wow-delay=".2s" data-wow-duration="2000ms">
                        <div className="text-subtitle text-primary">Explore Cities</div>
                        <h4 className="mt-4">Our Location For You</h4>
                    </div>
                        <div className="wow fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
                            <Swiper
                                spaceBetween={15}
                                slidesPerView={4}
                                ref={slidercityRef}
                                className="swiper tf-sw-location overlay"
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                       
                                    },
                                    480: {
                                        slidesPerView: 2,
                                       
                                    },
                                    640: {
                                        slidesPerView: 3,
                                        
                                    },
                                    768: {
                                        slidesPerView: 2,
                                       
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                       
                                    },
                                    1280: {
                                        slidesPerView: 4,
                                       
                                    },
                                }}
                            >
                                {featuredcitiesdata && featuredcitiesdata.map((location, index) => {
                                    return (
                                        <SwiperSlide>

                                            <a href={`/property-in/${location.cities_slug}`} key={index} className="box-location">
                                                <div class="image">
                                                    <img src={location.cities_image ? imgUrl + location.cities_image : constant.DEFAULT_IMAGE} alt={location.cities_name} />
                                                </div>
                                                <div class="content">
                                                    <span class="sub-title">{location.property_count} Property</span>
                                                    <h6 class="title">{location.cities_name}</h6>
                                                </div>
                                            </a>
                                        </SwiperSlide>
                                    )
                                }
                                )}
                                   <div class="box-navigation">
                                <div class="navigation swiper-nav-next nav-next-location" onClick={handlePrev}><span class="icon icon-arr-l"></span></div>
                                <div class="navigation swiper-nav-prev nav-prev-location" onClick={handleNext}><span class="icon icon-arr-r"></span></div> 
                            </div>
                            </Swiper>
                         
                        </div>

                    </div>
                </section></> : null}



    </>)
}

export default HomeCities