import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function Services() {
  return (
    <>
    <Header/>
    <section className="flat-title-page">
                <div className="container">
                    <h2 className="text-center">Our Services</h2>
                   
                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li>/ Services</li>
                    </ul>
                    
                </div>
            </section>
    <div>Services</div>
    <Footer/>
    
    </>
  )
}

export default Services