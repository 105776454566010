import { ApiService } from "../../Components/Services/apiservices"
import React from 'react';
import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import PropertyBox from "../../Components/Element/property_box";
import ProjectBox from "../../Components/Element/project_box";

const FeaturedProjects = () => {
    const [featuredprojectsdata, setfeaturedProjectsdata] = useState([])
    const [imgUrl, setimgUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getfeaturedprojects()
        }
        didMountRef.current = false
    }, [])

    const getfeaturedprojects = () => {
        setIsLoading(true)
        ApiService.fetchData('projectList ').then((res) => {
            if (res.status == 'success') {
                setimgUrl(res.project_img_url)
                setfeaturedProjectsdata(res.resProjectData)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500)
            }
            else {
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }
    return (<>
        {featuredprojectsdata && featuredprojectsdata.length > 0 ? <>

            <section className="flat-section flat-recommended pt-0 wow fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
            <div className="container">
                <div className="box-title style-2 text-center">
                    <div className="text-subtitle text-primary">Featured Projects</div>
                    <h4 className="mt-4">Discover Proopify's Finest Properties for Your Dream Home</h4>
                </div>
                <div className="row">
                    {featuredprojectsdata && featuredprojectsdata.map((item , index)=>{
                        return(<>
                            <div className="col-xl-6 col-md-6" key={index}>
                                <ProjectBox propertydetail={item} imgUrl={imgUrl}></ProjectBox>
                            </div>
                        </>)
                    })}
                </div>
                <div className="text-center">
                    <a href="#" className="tf-btn primary size-1">View All Properties</a>
                </div>
            </div>
        </section>
        </> : ''}
        

    </>)
}

export default FeaturedProjects